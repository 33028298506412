<script>
import { mergeData } from 'vue-functional-data-merge'

function getTag({ to, disabled }, parent) {
  return Boolean(parent.$router) && to && !disabled ? 'router-link' : 'a'
}

function clickHandlerFactory({ disabled, tag, href, suppliedHandler }) {
  return function onClick(e) {
    if (disabled && e instanceof Event) {
      // Stop event from bubbling up.
      e.stopPropagation()
      // Kill the event loop attached to this specific EventTarget.
      e.stopImmediatePropagation()
    } else if (typeof suppliedHandler === 'function') {
      suppliedHandler(...arguments)
    }
    if ((tag !== 'router-link' && href === '#') || disabled) {
      // Stop scroll-to-top behavior or navigation.
      e.preventDefault()
    }
  }
}

export default {
  functional: true,
  name: 'AppLink',
  props: {
    href: String,
    rel: String,
    target: {
      type: String,
      default: '_self',
    },
    disabled: Boolean,
    active: Boolean,
    // router-link props
    activeClass: {
      type: String,
      default: 'router-link-active',
    },
    append: Boolean,
    event: {
      type: [String, Array],
      default: 'click',
    },
    exact: Boolean,
    exactActiveClass: {
      type: String,
      default: 'router-link-exact-active',
    },
    replace: Boolean,
    routerTag: {
      type: String,
      default: 'a',
    },
    to: [String, Object],
  },
  render(h, { props, data, parent, children }) {
    const tag = getTag(props, parent)
    const rel = props.target === '_blank' && !props.rel ? 'noopner' : props.rel
    const href = props.href || '#'

    const eventType = tag === 'router-link' ? 'nativeOn' : 'on'
    const suppliedHandler = (data[eventType] || {}).click
    const handlers = {
      click: clickHandlerFactory({
        tag,
        href,
        disabled: props.disabled,
        suppliedHandler,
      }),
    }

    const tabindex = data.attrs ? data.attrs.tabindex : null

    const componentData = mergeData(data, {
      class: {
        disabled: props.disabled,
        active: props.active,
      },
      attrs: {
        rel,
        href,
        target: props.target,
        tabindex: props.disabled ? '-1' : tabindex,
        'aria-disabled': tag === 'a' && props.disabled ? 'true' : null,
      },
      props: Object.assign(props, { tag: props.routerTag }),
    })

    if (tag === 'router-link') {
      delete componentData.attrs.href
    }

    componentData[eventType] = Object.assign(
      componentData[eventType] || {},
      handlers
    )

    return h(tag, componentData, children)
  },
}
</script>
