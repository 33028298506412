<template>
  <div class="l-container">
    <div class="p-shopNews">
      <CSelect
        class="p-shopNews__select"
        v-model="selectedYear"
        :options="arciveOptions"
      />
      <div
        v-if="filteredInformationList.length"
        class="p-shopNews__list p-shopNewsList"
      >
        <ul class="p-shopNewsList__list">
          <li
            v-for="item in filteredInformationList"
            :key="item.id"
            class="p-shopNewsList__item p-shopNewsListItem"
          >
            <router-link
              class="p-shopNewsListItem__link"
              :to="{
                hash: '#shopNews',
                query: {
                  id: item.id,
                },
              }"
              replace
            >
              <article class="p-shopNewsListItem__inner">
                <time
                  class="p-shopNewsListItem__date"
                  v-text="
                    $options.filters.dayjsFormat(
                      item.publishedAt,
                      $t('YYYY年M月D日.お知らせpublishedAt')
                    )
                  "
                />
                <h1 class="p-shopNewsListItem__title" v-text="item.title" />
              </article>
            </router-link>
          </li>
        </ul>
      </div>
      <div v-else class="p-shopNews__empty">
        <p v-t="'登録情報がありません。'" />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import CSelect from '@/components/common/CSelect.vue'

export default {
  name: 'PShopNews',
  components: { CSelect },
  props: {
    informationList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedYear: '',
    }
  },
  watch: {
    arciveOptions: {
      handler(arciveOptions) {
        this.selectedYear = arciveOptions?.[0].value || ''
      },
      immediate: true,
    },
  },
  computed: {
    filteredInformationList() {
      const selectedYear = Number(this.selectedYear)
      return this.informationList.filter((obj) => {
        const publishedYear = dayjs(obj.publishedAt).year()
        return selectedYear === publishedYear
      })
    },
    arciveOptions() {
      const result = this.informationList
        .reduce((result, obj) => {
          const year = dayjs(obj.publishedAt).year()
          if (!result.some(({ value }) => value === year)) {
            result.push({
              value: year,
              label: this.$t('{year}年', { year }),
            })
          }
          return result
        }, [])
        .sort((a, b) => b - a)

      return result.length ? result : undefined
    },
  },
}
</script>
