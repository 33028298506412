<template>
  <article>
    <div class="p-shopInfo">
      <div class="l-container">
        <PShopHeader
          :shop-name="shopName"
          :updated-at="shop.updatedAt"
          :badges="badges"
        />
        <PShopData
          class="p-shopInfo__data"
          :shop="shop"
          :facility="facility"
          :trust-index="trustIndex"
          :measures="measures"
        />
      </div>
    </div>
    <CTab v-model="currentTab" :keys="tabKeys">
      <template #tab-PShopDetail>
        {{ $t('感染症.感染症対策状況タブ') }}<br class="h-spBreak" />{{
          $t('対策状況.感染症対策状況タブ')
        }}
      </template>
      <template #tab-PShopSensorInfo>
        {{ $t('エリア別.エリア別センサー情報タブ') }}<br class="h-spBreak" />{{
          $t('センサー情報.エリア別センサー情報タブ')
        }}
      </template>
      <template #tab-PShopNews>
        {{ $t('お知らせ.お知らせタブ') }}
      </template>
      <template #tab-PShopMap>
        {{ $t('地図.地図タブ') }}
      </template>
      <template #tab-PShopSurvey>
        {{ $t('アンケート.アンケートタブ') }}
      </template>

      <template #tabpanel-PShopDetail>
        <PShopDetail
          :shop="shop"
          :status="status"
          :organization="organization"
          :measures="measures"
          :main-sensor="mainSensor"
          :measure-image-list="measureImageList"
        />
      </template>
      <template #tabpanel-PShopSensorInfo>
        <PShopSensorInfo
          :sensor-list="sensorList"
          :status="status"
          :is-sensor-data-loading="isSensorDataLoading"
        />
      </template>
      <template #tabpanel-PShopNews>
        <PShopNews :information-list="informationList" />
      </template>
      <template #tabpanel-PShopMap>
        <PShopMap :address="address" />
      </template>
      <template #tabpanel-PShopSurvey>
        <PShopSurvey
          v-model="surveyValue"
          :questions="questions"
          :is-loading="isLoadingSurvey"
          @submit:survey="handleSubmitSurvey"
        />
      </template>
    </CTab>
    <PShopConfirmListModal
      :shop-confirm-list="shopConfirmList"
      :close-to="{ name: 'ShopDetail' }"
    />
    <PShopCertificateListModal
      :shop-certificate-list="certificateList"
      :close-to="{ name: 'ShopDetail' }"
    />
    <PShopCertificateModal
      v-if="certificateData"
      :shop-certificate-data="certificateData"
      :close-to="{ name: 'ShopDetail' }"
    />
    <PShopAboutTrustIndexModal :close-to="{ name: 'ShopDetail' }" />
    <template v-if="mainSensor || hasSensorList">
      <PShopAboutIotGraphModal :close-to="{ name: 'ShopDetail' }" />
      <PShopAboutIotValueModal :close-to="{ name: 'ShopDetail' }" />
    </template>
    <PShopNewsModal
      v-if="informationData"
      :close-to="{ name: 'ShopDetail' }"
      :information-data="informationData"
    />
  </article>
</template>

<script>
import PShopHeader from '@/components/shop/PShopHeader.vue'
import PShopData from '@/components/shop/PShopData.vue'
import CTab from '@/components/common/CTab.vue'
import PShopDetail from '@/components/shop/PShopDetail.vue'
import PShopSensorInfo from '@/components/shop/PShopSensorInfo.vue'
import PShopNews from '@/components/shop/PShopNews.vue'
import PShopMap from '@/components/shop/PShopMap.vue'
import PShopSurvey from '@/components/shop/PShopSurvey.vue'
import PShopConfirmListModal from '@/components/shop/PShopConfirmListModal.vue'
import PShopCertificateListModal from '@/components/shop/PShopCertificateListModal.vue'
import PShopCertificateModal from '@/components/shop/PShopCertificateModal.vue'
import PShopAboutTrustIndexModal from '@/components/shop/PShopAboutTrustIndexModal.vue'
import PShopAboutIotGraphModal from '@/components/shop/PShopAboutIotGraphModal.vue'
import PShopAboutIotValueModal from '@/components/shop/PShopAboutIotValueModal.vue'
import PShopNewsModal from '@/components/shop/PShopNewsModal.vue'

export default {
  name: 'ShopDetailPresentation',
  components: {
    PShopHeader,
    PShopData,
    CTab,
    PShopDetail,
    PShopSensorInfo,
    PShopNews,
    PShopMap,
    PShopSurvey,
    PShopConfirmListModal,
    PShopCertificateListModal,
    PShopCertificateModal,
    PShopAboutTrustIndexModal,
    PShopAboutIotGraphModal,
    PShopAboutIotValueModal,
    PShopNewsModal,
  },
  props: {
    isPublicResources: {
      type: Boolean,
      default: true,
    },
    shop: {
      type: Object,
      default: null,
    },
    facility: {
      type: Object,
      default: null,
    },
    organization: {
      type: Object,
      default: null,
    },
    measures: {
      type: Object,
      default: null,
    },
    measureImageList: {
      type: Array,
      required: true,
      default: () => [],
    },
    shopConfirmList: {
      type: Array,
      required: true,
      default: () => [],
    },
    trustIndex: {
      type: Number,
      required: true,
      default: 0,
    },
    informationList: {
      type: Array,
      required: true,
      default: () => [],
    },
    mainSensor: {
      type: Object,
      default: null,
    },
    hasSensorList: {
      type: Boolean,
      default: false,
    },
    sensorList: {
      type: Array,
      default: () => [],
    },
    isSensorDataLoading: {
      type: Boolean,
      default: false,
    },
    certificateList: {
      type: Array,
      required: true,
      default: () => [],
    },
    certificateData: {
      type: Object,
      default: null,
    },
    informationData: {
      type: Object,
      default: null,
    },
    surveyInfo: {
      type: Array,
      default: () => [],
    },
    surveyData: {
      type: Array,
      default: () => [],
    },
    loadingStack: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentTab: 'PShopDetail',
    }
  },
  computed: {
    shopName() {
      let shopName
      switch (this.$i18n.locale) {
        case 'en':
          shopName = this.shop.nameEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return shopName || this.shop.name
    },
    address() {
      let address
      switch (this.$i18n.locale) {
        case 'en':
          address = this.shop.addressEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return address || this.shop.address
    },
    badges() {
      const result = []

      if (this.mainSensor || this.hasSensorList) {
        result.push('iotSensor')
      }
      if (this.shopConfirmList.length) {
        result.push('shopConfirm')
      }

      return result
    },
    status() {
      return {
        shopConfirmedAt: this.isPublicResources
          ? this.shopConfirmList.length
            ? this.shopConfirmList[0].confirmedAt
            : false
          : null,
        shopCertificatedAt: this.certificateList.length
          ? this.certificateList[0].certificatedAt
          : false,
      }
    },
    questions() {
      return this.surveyInfo.filter(({ published }) => published)
    },
    hasSurvey() {
      return this.questions.length > 0
    },
    surveyValue: {
      get() {
        return this.surveyData
      },
      set(next) {
        this.$emit('update:surveyData', next)
      },
    },
    isLoadingSurvey() {
      return this.loadingStack.includes('postSurveyData')
    },
    tabKeys() {
      return [
        'PShopDetail',
        this.hasSensorList && 'PShopSensorInfo',
        'PShopNews',
        'PShopMap',
        this.hasSurvey && 'PShopSurvey',
      ].filter((item) => item)
    },
  },
  methods: {
    handleSubmitSurvey() {
      this.$emit('submit:survey')
    },
    getRequestTab() {
      if (this.$route.query.currentTab) {
        if (
          this.$route.query.currentTab == 'Detail' ||
          this.$route.query.currentTab == 'SensorInfo' ||
          this.$route.query.currentTab == 'News' ||
          this.$route.query.currentTab == 'Map' ||
          this.$route.query.currentTab == 'Survey'
        ) {
          this.currentTab = 'PShop' + this.$route.query.currentTab
          if (
            !this.hasSensorList &&
            this.$route.query.currentTab == 'SensorInfo'
          ) {
            this.currentTab = 'PShopDetail'
          }
          if (!this.hasSurvey && this.$route.query.currentTab == 'Survey') {
            this.currentTab = 'PShopDetail'
          }
        } else {
          this.currentTab = 'PShopDetail'
        }
      } else {
        // this.currentTab = 'PShopDetail';
      }
    },
  },
  created() {
    this.getRequestTab()
  },
}
</script>
