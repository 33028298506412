<template>
  <div class="c-radioButton">
    <label class="c-radioButton__control">
      <input
        type="radio"
        class="c-radioButton__input"
        :name="name"
        :value="value"
        v-model="model"
      />
      <span class="c-radioButton__symbol" />
      <span class="c-radioButton__label">
        <slot :checked="model">{{ label }}</slot>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CRadioButton',
  model: {
    prop: 'checked',
  },
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    model: {
      get() {
        return this.checked
      },
      set(next) {
        this.$emit('input', next)
      },
    },
  },
}
</script>
