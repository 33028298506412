<template>
  <div class="p-shopImageGallery">
    <div class="p-shopImageGallery__viewport" ref="viewport">
      <transition-group
        tag="ul"
        class="p-shopImageGallery__list"
        name="a-shopImageGalleryItem"
      >
        <li
          v-for="(image, index) in filteredImages"
          :key="image.category + index"
          class="p-shopImageGallery__item"
        >
          <div class="p-shopImageGallery__visual">
            <PShopMeasuresIcon
              class="p-shopImageGallery__icon"
              :icon="image.category"
              :portal-id="portalId"
            />
            <CImage :src="image.src" size="3p2" cover />
          </div>
        </li>
      </transition-group>
    </div>
    <button
      v-if="hasMore"
      :class="[
        'p-shopImageGallery__button',
        'c-button',
        'c-button--assist',
        'c-button--iconOpen',
        'c-button--iconLeft',
        {
          'c-button--iconUpsideDown': expanded,
        },
      ]"
      v-t="buttonLabel"
      @click="handleClickMoreButton"
    />
  </div>
</template>

<script>
import PShopMeasuresIcon from '@/components/shop/PShopMeasuresIcon.vue'
import CImage from '@/components/common/CImage.vue'
import { gsap } from 'gsap'

export default {
  name: 'PShopImageGallery',
  components: {
    PShopMeasuresIcon,
    CImage,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    filteredImages() {
      return this.expanded ? this.images : this.images.slice(0, 8)
    },
    hasMore() {
      return this.images.length > 8
    },
    buttonLabel() {
      return !this.expanded ? 'もっと見る' : '閉じる'
    },
    viewport() {
      return this.$refs.viewport
    },
  },
  methods: {
    handleClickMoreButton() {
      const prevViewportHeight = this.viewport.clientHeight

      this.viewport.style.height = `${prevViewportHeight}px`

      this.expanded = !this.expanded

      this.$nextTick(() => {
        const nextViewportHeight = this.getNextViewportHeight()
        gsap.to(this.viewport, {
          height: nextViewportHeight,
          duration: 0.3,
          ease: 'power4.out',
          onComplete: () => {
            this.viewport.style.height = ''
          },
        })
      })
    },
    getNextViewportHeight() {
      const length = this.filteredImages.length
      const lastItem = this.viewport.querySelector(
        `.p-shopImageGallery__item:nth-child(${length}) .p-shopImageGallery__visual`
      )

      const lastItemRect = lastItem.getBoundingClientRect()
      const viewportRect = this.viewport.getBoundingClientRect()

      return lastItemRect.bottom - viewportRect.top
    },
  },
}
</script>
