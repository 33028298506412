<template>
  <ul class="p-shopSurveyRadioButton">
    <li
      v-for="value in values"
      :key="value"
      class="p-shopSurveyRadioButton__item"
    >
      <CRadioButton :name="name" :value="value" v-model="model">
        <slot :name="`item-${value}`">{{ value }}</slot>
      </CRadioButton>
    </li>
  </ul>
</template>

<script>
import CRadioButton from '@/components/common/CRadioButton.vue'

export default {
  name: 'PShopSurveyRadioButton',
  model: {
    prop: 'checked',
  },
  components: {
    CRadioButton,
  },
  props: {
    values: {
      type: Array,
      required: true,
    },
    checked: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    model: {
      get() {
        return this.checked
      },
      set(next) {
        this.$emit('input', next)
      },
    },
  },
}
</script>
