<template>
  <CModal
    class="p-shopConfirmListModal"
    dialog-id="shopConfirmList"
    :show="show"
    :close-to="closeTo"
    :wysiwyg-content="false"
  >
    <template slot="title">
      <PShopBadge class="c-modal__titleIcon" badge="shopConfirm" />
      <span v-t="'第三者チェック履歴'" />
    </template>
    <template slot="content">
      <dl class="p-shopConfirmListModal__list">
        <PShopConfirmListItem
          v-for="(item, index) in shopConfirmList"
          :key="index"
          :data="item"
          class="p-shopConfirmListModal__item"
        />
      </dl>
    </template>
  </CModal>
</template>

<script>
import CModal from '@/components/common/CModal.vue'
import PShopBadge from '@/components/common/PShopBadge.vue'
import PShopConfirmListItem from '@/components/shop/PShopConfirmListItem.vue'

export default {
  name: 'PShopConfirmModal',
  components: {
    CModal,
    PShopBadge,
    PShopConfirmListItem,
  },
  props: {
    closeTo: {
      type: [String, Object],
      default: '',
    },
    shopConfirmList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    show() {
      return (
        this.$route.hash === '#shopConfirmList' &&
        this.shopConfirmList.length > 0
      )
    },
  },
}
</script>
