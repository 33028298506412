<template>
  <CModal
    class="p-shopCertificateListModal"
    dialog-id="shopCertificateList"
    :show="show"
    :close-to="closeTo"
    :wysiwyg-content="false"
  >
    <template slot="title">統合検証証明書チェック履歴</template>
    <template slot="content">
      <ul class="p-shopCertificateListModal__list">
        <li
          v-for="item in list"
          :key="item.id"
          class="p-shopCertificateListModal__item"
        >
          <router-link
            :to="{
              hash: '#shopCertificate',
              query: {
                id: item.id,
              },
            }"
            replace
          >
            <dl class="p-shopCertificateListModal__itemInner">
              <dt
                v-text="item.checkLabel"
                class="p-shopCertificateListModal__title"
              />
              <dd class="p-shopCertificateListModal__content">
                <div class="p-shopCertificateListModal__value">
                  {{ item.count }}人
                </div>
                <div
                  class="p-shopCertificateListModal__date"
                  v-text="$options.filters.dayjsFormat(item.certificatedAt)"
                />
                <div class="p-shopCertificateListModal__icon">
                  <img
                    src="@/assets/img/icon/shop_certificate/ico_certificate_list.svg"
                    alt=""
                  />
                </div>
              </dd>
            </dl>
          </router-link>
        </li>
      </ul>
    </template>
  </CModal>
</template>

<script>
import CModal from '@/components/common/CModal.vue'
import dayjs from 'dayjs'

export default {
  name: 'PShopCertificateListModal',
  components: {
    CModal,
  },
  props: {
    closeTo: {
      type: [String, Object],
      default: '',
    },
    shopCertificateList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    list() {
      return this.shopCertificateList
        .concat()
        .sort((a, b) =>
          dayjs(b.verificationDate).diff(a.verificationDate, 'milliseconds')
        )
    },
    show() {
      return (
        this.$route.hash === '#shopCertificateList' &&
        this.shopCertificateList.length > 0
      )
    },
  },
}
</script>
