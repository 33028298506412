<template>
  <CModal
    class="p-shopAboutTrustIndexModal"
    dialog-id="shopConfirmList"
    :show="show"
    :close-to="closeTo"
  >
    <template slot="title">{{
      $t('{trustIndex}とは？', {
        trustIndex: $t(`${portalId}.おみせのトラスト指数`),
      })
    }}</template>
    <template slot="content">
      <p
        class="content"
        v-t="
          `${portalId}.お店と施設それぞれの衛生対策状況をパーセンテージで示す、おみせのトラスト独自の信頼性指数です。トラスト指数が高いほど、衛生対策の信頼性が高いお店となります。`
        "
      />
      <div class="p-shopAboutTrustIndexModal__point">
        <p
          class="p-shopAboutTrustIndexModal__pointHeading"
          v-t="'トラスト指数の内訳'"
        />
        <ul class="p-shopAboutTrustIndexModal__pointList">
          <li class="p-shopAboutTrustIndexModal__pointItem">
            <dl class="p-shopAboutTrustIndexModal__pointContent">
              <dt
                class="p-shopAboutTrustIndexModal__pointTitle"
                v-t="`${portalId}.お店の衛生対策状況`"
              />
              <dd
                class="p-shopAboutTrustIndexModal__pointDescription"
                v-t="
                  `${portalId}.換気/店舗消毒/お客様の安全/テーブル間距離/従業員の安全衛生管理/トイレ消毒の6カテゴリのチェック項目`
                "
              />
            </dl>
            <ul class="p-shopAboutTrustIndexModal__pointIcons">
              <li class="p-shopAboutTrustIndexModal__pointIcon">
                <PShopMeasuresIcon
                  icon="ventilation"
                  mobile
                  active
                  :portal-id="portalId"
                />
              </li>
              <li class="p-shopAboutTrustIndexModal__pointIcon">
                <PShopMeasuresIcon
                  icon="disinfect"
                  mobile
                  active
                  :portal-id="portalId"
                />
              </li>
              <li class="p-shopAboutTrustIndexModal__pointIcon">
                <PShopMeasuresIcon
                  icon="customer"
                  mobile
                  active
                  :portal-id="portalId"
                />
              </li>
              <li class="p-shopAboutTrustIndexModal__pointIcon">
                <PShopMeasuresIcon
                  icon="distance"
                  mobile
                  active
                  :portal-id="portalId"
                />
              </li>
              <li class="p-shopAboutTrustIndexModal__pointIcon">
                <PShopMeasuresIcon
                  icon="staff"
                  mobile
                  active
                  :portal-id="portalId"
                />
              </li>
              <li class="p-shopAboutTrustIndexModal__pointIcon">
                <PShopMeasuresIcon
                  icon="restroom"
                  mobile
                  active
                  :portal-id="portalId"
                />
              </li>
            </ul>
          </li>
          <li class="p-shopAboutTrustIndexModal__pointItem">
            <dl class="p-shopAboutTrustIndexModal__pointContent">
              <dt
                class="p-shopAboutTrustIndexModal__pointTitle"
                v-t="`${portalId}.施設の衛生対策状況`"
              />
              <dd
                class="p-shopAboutTrustIndexModal__pointDescription"
                v-t="'換気/トイレ消毒の2カテゴリのチェック項目'"
              />
            </dl>
            <ul class="p-shopAboutTrustIndexModal__pointIcons">
              <li class="p-shopAboutTrustIndexModal__pointIcon">
                <PShopMeasuresIcon
                  icon="ventilation"
                  mobile
                  active
                  :portal-id="portalId"
                />
              </li>
              <li class="p-shopAboutTrustIndexModal__pointIcon">
                <PShopMeasuresIcon
                  icon="restroom"
                  mobile
                  active
                  :portal-id="portalId"
                />
              </li>
            </ul>
          </li>
          <li class="p-shopAboutTrustIndexModal__pointItem">
            <dl class="p-shopAboutTrustIndexModal__pointContent">
              <dt
                class="p-shopAboutTrustIndexModal__pointTitle"
                v-t="'IoTセンサーでの情報開示有無'"
              />
              <dd
                class="p-shopAboutTrustIndexModal__pointDescription"
                v-t="`${portalId}.お店にセンサーを設置し、数値を開示しているか`"
              />
            </dl>
            <ul class="p-shopAboutTrustIndexModal__pointIcons">
              <li class="p-shopAboutTrustIndexModal__pointIcon">
                <PShopBadge badge="iotSensor" large />
              </li>
            </ul>
          </li>
          <li class="p-shopAboutTrustIndexModal__pointItem">
            <dl class="p-shopAboutTrustIndexModal__pointContent">
              <dt
                class="p-shopAboutTrustIndexModal__pointTitle"
                v-t="'第三者チェックの実施有無'"
              />
              <dd
                class="p-shopAboutTrustIndexModal__pointDescription"
                v-t="'第三者チェックを行っているか'"
              />
            </dl>
            <ul class="p-shopAboutTrustIndexModal__pointIcons">
              <li class="p-shopAboutTrustIndexModal__pointIcon">
                <PShopBadge badge="shopConfirm" large />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </template>
  </CModal>
</template>

<script>
import CModal from '@/components/common/CModal.vue'
import PShopBadge from '@/components/common/PShopBadge.vue'
import PShopMeasuresIcon from '@/components/shop/PShopMeasuresIcon.vue'

export default {
  name: 'PShopAboutTrustIndexModal',
  components: {
    CModal,
    PShopMeasuresIcon,
    PShopBadge,
  },
  props: {
    closeTo: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    show() {
      return this.$route.hash === '#aboutTrustIndex'
    },
  },
}
</script>
