<template>
  <table class="p-shopMeasuresTable">
    <tbody>
      <tr
        v-for="row in rows"
        :key="row.category"
        class="p-shopMeasuresTable__row"
      >
        <th>
          <p class="p-shopMeasuresTable__pointTitle">
            <PShopMeasuresIcon
              class="p-shopMeasuresTable__icon"
              :icon="row.category"
              :active="row.active"
              :portal-id="portalId"
            />
            <span v-html="$t(row.label)" />
          </p>
        </th>
        <td>
          <p
            v-for="(point, index) in row.point"
            :key="index"
            class="p-shopMeasuresTable__point"
          >
            <span
              :class="[
                'p-shopMeasuresTable__pointStatus',
                `p-shopMeasuresTable__pointStatus--${point.status}`,
              ]"
            />
            <span v-html="$t(point.text)" />
          </p>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import PShopMeasuresIcon from '@/components/shop/PShopMeasuresIcon.vue'

export default {
  name: 'PShopMeasuresTable',
  components: {
    PShopMeasuresIcon,
  },
  props: {
    measures: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    measuresCategory() {
      return {
        ventilation: '換気',
        disinfect: `${this.portalId}.店舗消毒`,
        customer: `${this.portalId}.お客様の安全`,
        distance: `${this.portalId}.テーブル間距離`,
        staff: `${this.portalId}.従業員の安全衛生管理`,
        restroom: 'トイレ消毒',
        certification: '認証の取得',
      }
    },
    measuresPoint() {
      return {
        ventilation: {
          volume:
            '建築基準法の必要換気量を確保 （一人当たり30㎥/時もしくは、床と窓の面積比が５:1程度）',
          running: '換気設備の運転状況を確認、または定期的な窓の開放を実施',
        },
        disinfect: {
          facility: `${this.portalId}.ドアノブ、券売機、セルフドリンクコーナーなどのお客様フロアにある設備の消毒`,
          table: `${this.portalId}.お客様の退席後、テーブル上の消毒もしくはテーブルクロスの交換を実施`,
        },
        customer: {
          disinfect: `${this.portalId}.入口における消毒液を設置、入店時お客様にて消毒実施`,
          thermometry: `${this.portalId}.入店時お客様に検温を実施`,
        },
        distance: {
          table: `${this.portalId}.グループ同士で１ｍの間隔が空けられているもしくはパーティションで区切られている`,
        },
        staff: {
          healthManagement: `${this.portalId}.従業員の検温、体調管理を実施`,
          wearingMask: `${this.portalId}.従業員のマスクまたはフェイスガードの着用`,
        },
        restroom: {
          disinfect: 'トイレ定期清掃・消毒、消毒液設置を実施',
        },
        certification: {
          mark: '感染防止対策に対する自治体等の第三者が発行する認証を取得',
        },
      }
    },
    rows() {
      // category => ventilation, disinfect, customer, distance, staff, restroom
      return Object.keys(this.measures).reduce((rows, category) => {
        const data = this.measures[category]

        // dataKey => etc
        const point = Object.keys(data).reduce((point, dataKey) => {
          if (data[dataKey] !== null && data[dataKey]) {
            point.push({
              text: this.measuresPoint[category][dataKey],
              status: data[dataKey] ? 'solved' : 'unmeasured',
            })
          }

          return point
        }, [])

        if (point.length) {
          rows.push({
            category,
            label: this.measuresCategory[category],
            active: this.getMeasuresActiveStatus(category, point),
            point,
          })
        }

        return rows
      }, [])
    },
  },
  methods: {
    getMeasuresActiveStatus(category, measures) {
      const measuresKeys = Object.keys(measures)

      switch (category) {
        case 'ventilation':
        case 'restroom':
          return measuresKeys.some(
            (measureKey) => measures[measureKey].status === 'solved'
          )
        default:
          return measuresKeys.every(
            (measureKey) => measures[measureKey].status === 'solved'
          )
      }
    },
  },
}
</script>
