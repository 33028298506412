<template>
  <section :class="['p-shopGraph', computedGraphClass]">
    <div class="p-shopGraph__inner">
      <h3 class="p-shopGraph__title" v-t="data.label" />
      <p class="p-shopGraph__current">
        <PShopGraphIcon
          class="p-shopGraph__icon"
          :icon-name="iconName"
          :fill-color="iconFillColor"
        />
        <span class="p-shopGraph__value" v-text="valueLabel" />
      </p>
      <dl class="p-shopGraph__status">
        <dt class="p-shopGraph__statusTitle" v-t="data.label" />
        <dd class="p-shopGraph__statusContent" v-text="statusLabel" />
      </dl>
      <PShopIotChart
        class="p-shopGraph__chart"
        :key="chartId"
        :data="dataset"
        :y-axis-suggested-max="suggestedMax"
        :x-axis-min-tick="rangeStart"
        :x-axis-max-tick="rangeEnd"
        :x-axis-tick-unit="xAxisTickUnit"
        :y-axis-max-value="yAxisMaxValue"
      />
    </div>
  </section>
</template>

<script>
import PShopIotChart from '@/components/shop/PShopIotChart.vue'
import PShopGraphIcon from '@/components/shop/PShopGraphIcon.vue'
import dayjs from 'dayjs'
import {
  iconNameMap,
  fillColorMap,
  rangeMap,
} from '@/assets/request/viewModel/SensorDataViewModel'

export default {
  name: 'PShopGraph',
  components: {
    PShopIotChart,
    PShopGraphIcon,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    renderedAt: {
      type: String,
      required: true,
    },
    range: {
      type: String,
      required: true,
    },
  },
  computed: {
    chartId() {
      return `${this.$route.params.id}-${this.range}`
    },
    ranges() {
      return Object.keys(rangeMap)
    },
    rangeSetting() {
      return rangeMap[this.range]
    },
    dataset() {
      return this.data.dataList[this.rangeSetting.datasetName]
    },
    computedGraphClass() {
      return [this.computedLevel].reduce((classList, value) => {
        if (typeof value === 'undefined') return classList

        classList.push('p-shopGraph--' + value)

        return classList
      }, [])
    },
    computedLatestStatus() {
      return this.data.latestStatus[this.rangeSetting.datasetName]
    },

    // 表示用の算出プロパティ
    value() {
      return this.showStatus ? this.computedLatestStatus.value || '-' : '-'
    },
    unit() {
      return (this.data.unit || '').replace('℃', '°C')
    },
    showStatus() {
      const statusTimestamp = dayjs(this.computedLatestStatus.timestamp)
      return (
        statusTimestamp.isAfter(this.rangeStart) &&
        statusTimestamp.isBefore(this.rangeEnd)
      )
    },
    valueLabel() {
      return `${this.value}${this.unit}`
    },
    statusLabel() {
      return this.showStatus ? this.$t(this.computedLatestStatus.status) : ''
    },
    computedLevel() {
      return this.showStatus ? this.computedLatestStatus.level : undefined
    },
    iconName() {
      return iconNameMap[this.computedLevel] || 'faceNeutral'
    },
    iconFillColor() {
      return fillColorMap[this.computedLevel] || '#999'
    },
    xAxisTickUnit() {
      return this.rangeSetting.xAxisUnit
    },
    suggestedMax() {
      return this.data.suggestedMax
    },
    yAxisMaxValue() {
      return this.data.yAxisMaxValue
    },
    rangeStart() {
      return this.getCurrentRange()
    },
    rangeEnd() {
      return this.getCurrentRange('end')
    },
  },
  methods: {
    getCurrentRange(name = 'start') {
      const calcFunc = this.rangeSetting.range[name]
      return calcFunc ? calcFunc(this.renderedAt) : this.renderedAt
    },
  },
}
</script>
