<template>
  <transition
    name="a-modal"
    @before-enter="enableModal"
    @after-enter="focusDialog"
    @before-leave="disableModal"
    appear
  >
    <div class="c-modal" v-show="show" @click="closeModal">
      <div
        ref="dialog"
        :id="dialogId"
        class="c-modal__dialog"
        role="dialog"
        tabindex="-1"
        :aria-labelledby="titleId"
        :aria-modal="`${show}`"
        :aria-hidden="`${!show}`"
        @click.stop
      >
        <div class="c-modal__header">
          <div :id="titleId" class="c-modal__title">
            <slot name="title" />
          </div>
          <app-link class="c-modal__close" :to="closeTo" replace>
            <img
              src="@/assets/img/icon/common/ico_close.svg"
              :alt="$t('モーダルを閉じる')"
            />
          </app-link>
        </div>
        <hr class="c-modal__border" aria-hidden="true" />
        <div class="c-modal__body">
          <div :class="['c-modal__content', { content: wysiwygContent }]">
            <slot name="content" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  focusFirstDescendant,
  focusLastDescendant,
  IgnoreUtilFocusChanges,
} from '@/assets/modules/wai-aria/focusTrap/util'
import { KeyCode } from '@/assets/modules/wai-aria/util'

export default {
  name: 'CModal',
  inject: ['setModal'],
  props: {
    dialogId: String,
    show: {
      type: Boolean,
      default: true,
    },
    closeTo: {
      type: [String, Object],
      default: '',
    },
    wysiwygContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      beforeFocus: undefined,
      lastFocus: undefined,
    }
  },
  computed: {
    dialog() {
      return this.$refs.dialog
    },
    titleId() {
      const key = () => Math.random().toString(36).replace('0.', '')
      return '__title_id__' + (this._uid || key())
    },
  },
  beforeDestroy() {
    this.disableModal()
  },
  methods: {
    closeModal() {
      this.$router.replace(this.closeTo)
    },
    enableModal() {
      this.$nextTick(() => {
        this.setModal(true)
        this.attachFocusTrap()
        this.attachKeyEvent()
      })
    },
    focusDialog() {
      this.beforeFocus = document.activeElement
      this.dialog.focus()
    },
    disableModal() {
      this.setModal(false)
      this.removeFocusTrap()
      this.removeKeyEvent()
      this.beforeFocus?.focus()
    },
    attachFocusTrap() {
      document.addEventListener('focus', this.focusTrap, true)
    },
    removeFocusTrap() {
      document.removeEventListener('focus', this.focusTrap, true)
    },
    focusTrap(event) {
      if (IgnoreUtilFocusChanges) return

      const target = event.target
      const dialog = this.dialog

      if (dialog.contains(target)) {
        this.lastFocus = target
      } else {
        focusFirstDescendant(dialog)
        if (this.lastFocus === document.activeElement) {
          focusLastDescendant(dialog)
        }
        this.lastFocus = document.activeElement
      }
    },
    attachKeyEvent() {
      document.addEventListener('keydown', this.handleEscapeKeyDown)
    },
    removeKeyEvent() {
      document.removeEventListener('keydown', this.handleEscapeKeyDown)
    },
    handleEscapeKeyDown(event) {
      if (event.keyCode === KeyCode.Esc) {
        this.closeModal()
      }
    },
  },
}
</script>
