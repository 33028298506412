<template>
  <div class="l-container">
    <div class="p-shopMap">
      <div class="p-shopMap__inner">
        <div class="p-shopMap__frame">
          <iframe
            :src="src"
            width="600"
            height="450"
            style="border: 0"
            allowfullscreen
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PShopMap',
  props: {
    address: {
      type: String,
      required: true,
    },
  },
  computed: {
    src() {
      return encodeURI(
        `https://maps.google.com/maps?q=${this.address}&hl=${this.$i18n.locale}&output=embed`
      )
    },
  },
}
</script>
