<template>
  <ul class="p-shopIotRangeSwitch">
    <li v-for="range in ranges" :key="range" class="p-shopIotRangeSwitch__item">
      <label
        class="p-shopIotRangeSwitch__label"
        :class="{ 'p-shopIotRangeSwitch__label--active': range === checked }"
      >
        <input
          class="p-shopIotRangeSwitch__input"
          type="radio"
          :name="`__range_switch__${uid}`"
          :value="range"
          :checked="range === checked"
          @change="$emit('change', $event.target.value)"
        />
        <span class="p-shopIotRangeSwitch__text" v-text="range" />
      </label>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PShopIotRangeSwitch',
  model: {
    event: 'change',
    prop: 'checked',
  },
  props: {
    checked: {
      type: String,
      required: true,
      default: '1day',
    },
  },
  computed: {
    uid() {
      const key = () => Math.random().toString(36).replace('0.', '')
      return this._uid || key()
    },
    ranges() {
      return ['3hours', '1day', '1week']
    },
  },
}
</script>
