<template>
  <div class="p-shopConfirmListItem">
    <dt class="p-shopConfirmListItem__organization" v-text="organizationName" />
    <dd
      class="p-shopConfirmListItem__date"
      v-text="$options.filters.dayjsFormat(data.confirmedAt)"
    />
  </div>
</template>

<script>
export default {
  name: 'PShopConfirmListItem',
  props: {
    data: {
      type: [Object],
      default: null,
    },
  },
  computed: {
    organizationName() {
      let organizationName
      switch (this.$i18n.locale) {
        case 'en':
          organizationName = this.data.organizationEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return organizationName || this.data.organization
    },
  },
}
</script>
