<template>
  <div class="l-container">
    <div class="p-shopDetail">
      <div class="p-shopDetail__help">
        <PShopHelpList :show-sensor-help="isSensorShown" :status="status" />
        <PShopIotRangeSwitch
          v-if="isSensorShown"
          class="p-shopDetail__switch"
          v-model="selectedRange"
        />
      </div>

      <div class="p-shopDetail__sensor">
        <PShopIotStatus
          v-if="isSensorShown"
          :data-list="mainSensor.dataList"
          :selected-range="selectedRange"
        />
      </div>

      <section class="p-shopMeasures p-shopDetail__measures">
        <div class="p-shopMeasures__inner">
          <h3 class="p-shopMeasures__title" v-t="`${portalId}.店舗の対策`" />
          <PShopMeasuresTable
            class="p-shopMeasures__table"
            :measures="measures.shop"
          />
        </div>
      </section>

      <section class="p-shopMeasures p-shopDetail__measures">
        <div class="p-shopMeasures__inner">
          <h3 class="p-shopMeasures__title" v-t="`${portalId}.施設の対策`" />
          <PShopMeasuresTable
            class="p-shopMeasures__table"
            :measures="measures.facility"
          />
        </div>
      </section>

      <small class="c-note p-shopDetail__note" data-mark="※">
        <i18n
          tag="span"
          :path="`${portalId}.対策状況のうち、店舗の対策情報は{shopName}、施設の対策情報は{facilityName}が登録しています。`"
        >
          <template v-slot:shopName>{{ shopName }}</template>
          <template v-slot:facilityName>
            <app-link
              v-if="organization.siteUrl"
              class="c-link"
              :href="organization.siteUrl"
              target="_blank"
              >{{ organizationName }}</app-link
            >
            <template v-else>{{ organizationName }}</template>
          </template>
        </i18n>
      </small>

      <section
        v-if="measureImageList.length"
        class="p-shopSection p-shopDetail__gallery"
      >
        <h2 class="p-shopSection__title" v-t="`${portalId}.おみせの取り組み`" />
        <div class="p-shopSection__content">
          <PShopImageGallery :images="measureImageList" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import PShopHelpList from '@/components/shop/PShopHelpList.vue'
import PShopIotRangeSwitch from '@/components/shop/PShopIotRangeSwitch.vue'
import PShopIotStatus from '@/components/shop/PShopIotStatus.vue'
import PShopMeasuresTable from '@/components/shop/PShopMeasuresTable.vue'
import PShopImageGallery from '@/components/shop/PShopImageGallery.vue'
import AppLink from '../AppLink.vue'

export default {
  name: 'PShopDetail',
  components: {
    PShopIotRangeSwitch,
    PShopHelpList,
    PShopIotStatus,
    PShopMeasuresTable,
    PShopImageGallery,
    AppLink,
  },
  props: {
    status: {
      type: Object,
      default: () => ({
        shopConfirmedAt: false,
        shopCertificatedAt: false,
      }),
    },
    shop: {
      type: Object,
      default: null,
    },
    organization: {
      type: Object,
      default: null,
    },
    measures: {
      type: Object,
      default: null,
    },
    mainSensor: {
      type: Object,
      default: null,
    },
    measureImageList: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      selectedRange: '1day',
    }
  },
  computed: {
    shopName() {
      let shopName
      switch (this.$i18n.locale) {
        case 'en':
          shopName = this.shop.nameEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return shopName || this.shop.name
    },
    organizationName() {
      let organizationName
      switch (this.$i18n.locale) {
        case 'en':
          organizationName = this.organization.nameEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return organizationName || this.organization.name
    },
    isSensorShown() {
      return this.mainSensor !== null
    },
  },
}
</script>
