var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CModal',{staticClass:"p-shopAboutIotGraphModal",attrs:{"dialog-id":"shopConfirmList","show":_vm.show,"close-to":_vm.closeTo,"wysiwyg-content":""}},[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.$t('グラフの見方について')))]),_c('template',{slot:"content"},[_c('dl',[_c('dt',{directives:[{name:"t",rawName:"v-t",value:('CO2濃度'),expression:"'CO2濃度'"}]}),_c('dd',[_c('p',{directives:[{name:"t",rawName:"v-t",value:(
            '屋内における二酸化炭素濃度は、400～1500ppm程度の間を上下します。屋内でも窓/換気扇/入口等の開閉により、濃度は低くなります。'
          ),expression:"\n            '屋内における二酸化炭素濃度は、400～1500ppm程度の間を上下します。屋内でも窓/換気扇/入口等の開閉により、濃度は低くなります。'\n          "}]}),_c('p',{directives:[{name:"t",rawName:"v-t",value:(
            '濃度が低ければ、屋外の二酸化炭素濃度と同等であり、センサー周辺には、二酸化炭素を発生させる原因が少ないものと判断します。逆に濃度が高ければ、密閉空間の換気が不十分であるかもしくは二酸化炭素を発生させる原因が多く存在します。つまり、密集している状態の可能性が高いと考えます。換気状況を見える化できていることを、グラフからご理解頂けます。'
          ),expression:"\n            '濃度が低ければ、屋外の二酸化炭素濃度と同等であり、センサー周辺には、二酸化炭素を発生させる原因が少ないものと判断します。逆に濃度が高ければ、密閉空間の換気が不十分であるかもしくは二酸化炭素を発生させる原因が多く存在します。つまり、密集している状態の可能性が高いと考えます。換気状況を見える化できていることを、グラフからご理解頂けます。'\n          "}]})])]),_c('dl',[_c('dt',{directives:[{name:"t",rawName:"v-t",value:('室温'),expression:"'室温'"}]}),_c('dd',[_c('p',{directives:[{name:"t",rawName:"v-t",value:(
            '屋内における温度は、季節/冷暖房/換気等の状況変化により、0～35℃程度の間を上下します。'
          ),expression:"\n            '屋内における温度は、季節/冷暖房/換気等の状況変化により、0～35℃程度の間を上下します。'\n          "}]}),_c('p',{directives:[{name:"t",rawName:"v-t",value:(
            '「機械換気未設置の場合は、常時、窓を少し開け、室温は18 ℃を目安にすること」との内容が政府より公表されています。'
          ),expression:"\n            '「機械換気未設置の場合は、常時、窓を少し開け、室温は18 ℃を目安にすること」との内容が政府より公表されています。'\n          "}]})])]),_c('dl',[_c('dt',{directives:[{name:"t",rawName:"v-t",value:('湿度'),expression:"'湿度'"}]}),_c('dd',[_c('p',{directives:[{name:"t",rawName:"v-t",value:(
            '屋内における湿度は、季節/冷暖房/換気等の状況変化により、0～100%の間を上下します。'
          ),expression:"\n            '屋内における湿度は、季節/冷暖房/換気等の状況変化により、0～100%の間を上下します。'\n          "}]}),_c('p',{directives:[{name:"t",rawName:"v-t",value:(
            '飛沫拡散防止の観点から「湿度は40%以上を目安にすること」との内容が政府より公表されています。'
          ),expression:"\n            '飛沫拡散防止の観点から「湿度は40%以上を目安にすること」との内容が政府より公表されています。'\n          "}]})])]),_c('dl',[_c('dt',{directives:[{name:"t",rawName:"v-t",value:('騒音'),expression:"'騒音'"}]}),_c('dd',{directives:[{name:"t",rawName:"v-t",value:('店内の人の密集/密接状況を騒音の大小で表示します。'),expression:"'店内の人の密集/密接状況を騒音の大小で表示します。'"}]})]),_c('dl',[_c('dt',{directives:[{name:"t",rawName:"v-t",value:('混雑指数'),expression:"'混雑指数'"}]}),_c('dd',{directives:[{name:"t",rawName:"v-t",value:(
          '店内の人の密集/密接状況を周辺のBluetooth機器数から求めた指数で表示します。'
        ),expression:"\n          '店内の人の密集/密接状況を周辺のBluetooth機器数から求めた指数で表示します。'\n        "}]})]),_c('dl',[_c('dt',{directives:[{name:"t",rawName:"v-t",value:('暑さ指数（WBGT（湿球黒球温度）：Wet Bulb Globe Temperature）'),expression:"'暑さ指数（WBGT（湿球黒球温度）：Wet Bulb Globe Temperature）'"}]}),_c('dd',[_c('p',{directives:[{name:"t",rawName:"v-t",value:(
            '室内を前提に、簡易的にWBGTを求めたものであり、熱中症になりやすさを示しています。'
          ),expression:"\n            '室内を前提に、簡易的にWBGTを求めたものであり、熱中症になりやすさを示しています。'\n          "}]})])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }