<template>
  <section class="p-shopTrustIndex">
    <div class="p-shopTrustIndex__inner">
      <div class="p-shopTrustIndex__header">
        <h2 class="p-shopTrustIndex__title" v-text="trustIndexLabel" />
        <app-link
          class="p-shopTrustIndex__link c-link c-link--help c-link--iconLeft"
          v-t="{
            path: '{trustIndex}とは？',
            args: {
              trustIndex: trustIndexLabel,
            },
          }"
          to="#aboutTrustIndex"
          replace
        />
      </div>
      <CProgress
        class="p-shopTrustIndex__progress"
        :value="computedTrustIndex"
        large
      />
      <ul class="p-shopTrustIndex__iconList">
        <li
          v-for="icon in icons"
          :key="icon.category"
          class="p-shopTrustIndex__iconListItem"
        >
          <CTooltip>
            <template slot="target">
              <PShopMeasuresIcon
                class="p-shopTrustIndex__icon"
                :icon="icon.category"
                :active="icon.status"
                small
                :portal-id="portalId"
              />
            </template>
            <template slot="content">
              <span v-html="$t(iconBalloon[icon.category])" />
            </template>
          </CTooltip>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import PShopMeasuresIcon from '@/components/shop/PShopMeasuresIcon.vue'
import CProgress from '@/components/common/CProgress.vue'
import CTooltip from '@/components/common/CTooltip.vue'

export default {
  name: 'PShopTrustIndex',
  components: {
    PShopMeasuresIcon,
    CProgress,
    CTooltip,
  },
  props: {
    trustIndex: {
      type: Number,
      default: 0,
    },
    measures: {
      type: Object,
      default: () => ({
        shop: {},
        facility: {},
      }),
    },
  },
  computed: {
    computedMeasures() {
      // groupKey => shop, facility
      return Object.keys(this.measures).reduce((measures, category) => {
        const block = this.measures[category]

        // measuresKey => ventilation, disinfect, customer, distance, staff, restroom
        Object.keys(block).forEach((measuresKey) => {
          const data = block[measuresKey]

          if (typeof measures[measuresKey] === 'undefined') {
            measures[measuresKey] = {}
          }

          // dataKey => etc
          Object.keys(data).forEach((dataKey) => {
            const value = data[dataKey]
            measures[measuresKey][`${category}-${dataKey}`] = value
          })
        })

        return measures
      }, {})
    },
    icons() {
      return Object.keys(this.computedMeasures).map((category) => ({
        category,
        status: this.getMeasuresResult(
          category,
          this.computedMeasures[category]
        ),
      }))
    },
    iconBalloon() {
      return {
        ventilation: '換気',
        disinfect: `${this.portalId}.店舗消毒`,
        customer: `${this.portalId}.お客様の安全`,
        distance: `${this.portalId}.テーブル間距離`,
        staff: `${this.portalId}.従業員の安全衛生管理`,
        restroom: 'トイレ消毒',
        certification: '認証の取得',
      }
    },
    computedTrustIndex() {
      return Math.min(100, this.trustIndex)
    },
    trustIndexLabel() {
      return this.$t(`${this.portalId}.おみせのトラスト指数`)
    },
  },
  methods: {
    getMeasuresResult(category, measures) {
      const measureKeys = Object.keys(measures)

      switch (category) {
        case 'ventilation':
        case 'restroom':
          return measureKeys.some((measureKey) => measures[measureKey])
        default:
          return measureKeys.every((measureKey) => measures[measureKey])
      }
    },
  },
}
</script>
