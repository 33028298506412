<template>
  <ul class="p-shopIotStatus">
    <li
      v-for="item in computedDataList"
      :key="item.label"
      class="p-shopIotStatus__item"
    >
      <PShopGraph
        :data="item"
        :rendered-at="renderedAt"
        :range="selectedRange"
      />
    </li>
  </ul>
</template>

<script>
import PShopGraph from '@/components/shop/PShopGraph.vue'
import dayjs from 'dayjs'

export default {
  name: 'PShopIotStatus',
  components: {
    PShopGraph,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    selectedRange: {
      type: String,
      required: true,
    },
  },
  computed: {
    renderedAt() {
      return dayjs(new Date()).second(0).millisecond(0).toISOString()
    },
    computedDataList() {
      return this.dataList.filter((data) => data.dataList)
    },
  },
}
</script>
