<template>
  <div class="c-select">
    <select :value="value" @change="$emit('change', $event.target.value)">
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        v-text="option.label"
      />
    </select>
  </div>
</template>

<script>
export default {
  name: 'CSelect',
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Array,
      default: () => [{ label: '-', value: '' }],
    },
  },
}
</script>
