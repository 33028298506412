<template>
  <CModal
    class="p-shopNewsModal"
    dialog-id="shopNews"
    :show="show"
    :close-to="closeTo"
    :wysiwyg-content="false"
  >
    <template slot="title">
      {{ informationData.title }}
    </template>
    <template slot="content">
      <div>
        <p
          class="p-shopNewsModal__date"
          v-text="
            $options.filters.dayjsFormat(
              informationData.publishedAt,
              $t('YYYY年M月D日.お知らせpublishedAt')
            )
          "
        />
        <p class="p-shopNewsModal__content" v-text="informationData.content" />
      </div>
    </template>
  </CModal>
</template>

<script>
import CModal from '@/components/common/CModal.vue'

export default {
  name: 'PShopNewsModal',
  components: { CModal },
  props: {
    closeTo: {
      type: [String, Object],
      default: '',
    },
    informationData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    show() {
      return this.$route.hash === '#shopNews'
    },
  },
}
</script>
