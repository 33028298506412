<template>
  <ul class="p-socialLink">
    <li v-for="item in items" :key="item.label" class="p-socialLink__item">
      <app-link
        class="p-socialLink__link"
        :href="item.href"
        :target="item.href ? '_blank' : null"
        @click="item.handler"
      >
        <img class="p-socialLink__icon" :src="item.icon" :alt="item.alt" />
      </app-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PSocialLink',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  computed: {
    pageUrl() {
      return location.href
    },
    encodedPageUrl() {
      return encodeURIComponent(this.pageUrl)
    },
    twitterLink() {
      return 'http://twitter.com/share?url=' + this.encodedPageUrl
    },
    facebookLink() {
      return 'http://www.facebook.com/share.php?u=' + this.encodedPageUrl
    },
    items() {
      return [
        {
          icon: require('@/assets/img/icon/share/ico_social_x.svg'),
          alt: 'Twitter',
          href: this.twitterLink,
        },
        {
          icon: require('@/assets/img/icon/share/ico_social_facebook.svg'),
          alt: 'Facebook',
          href: this.facebookLink,
        },
        {
          icon: require('@/assets/img/icon/share/ico_copy.svg'),
          alt: 'コピー',
          handler: this.copyUrlToClipboard,
        },
      ]
    },
  },
  methods: {
    copyUrlToClipboard() {
      const copyFrom = document.createElement('textarea')
      copyFrom.textContent = this.pageUrl
      const body = document.querySelector('body')
      body.appendChild(copyFrom)
      copyFrom.select()
      document.execCommand('copy')
      body.removeChild(copyFrom)
      this.$toasted.show(this.$t('リンクをコピーしました'))
    },
  },
}
</script>
