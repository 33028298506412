<template>
  <div class="l-container">
    <div class="p-shopSensorInfo">
      <PShopHelpList :show-sensor-help="true" :status="status" />
      <div class="p-shopSensorInfo__inner">
        <h3 class="p-shopSensorInfo__title" v-t="'センサー情報一覧'" />
        <table
          v-if="!isSensorDataLoading && sensorList.length"
          class="p-shopSensorInfoTable p-shopSensorInfo__table"
        >
          <thead>
            <tr class="p-shopSensorInfoTable__header">
              <th class="p-shopSensorInfoTable__title">&nbsp;</th>
              <th class="p-shopSensorInfoTable__label" v-t="'CO2濃度'" />
              <th class="p-shopSensorInfoTable__label" v-t="'室温'" />
              <th class="p-shopSensorInfoTable__label" v-t="'湿度'" />
              <th class="p-shopSensorInfoTable__label" v-t="'騒音'" />
              <th class="p-shopSensorInfoTable__label" v-t="'混雑指数'" />
              <th class="p-shopSensorInfoTable__label" v-t="'暑さ指数'" />
              <th class="p-shopSensorInfoTable__icon">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <PShopSensorInfoRow
              v-for="sensorData in sensorList"
              :sensorData="sensorData"
              :key="sensorData.id"
            />
          </tbody>
        </table>
        <div v-else class="p-shopSensorInfo__loading">
          <p
            v-if="!isSensorDataLoading && !sensorList.length"
            v-html="
              $t('情報を取得できませんでした。時間をおいて再度お試しください。')
            "
          />
          <CLoadingSpinner v-else class="p-shopSensorInfo__loadingSpinner" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PShopHelpList from '@/components/shop/PShopHelpList.vue'
import PShopSensorInfoRow from '@/components/shop/PShopSensorInfoRow.vue'
import CLoadingSpinner from '@/components/common/CLoadingSpinner.vue'

export default {
  name: 'PShopSensorInfo',
  components: {
    PShopHelpList,
    PShopSensorInfoRow,
    CLoadingSpinner,
  },
  props: {
    sensorList: {
      type: Array,
      default: null,
    },
    status: {
      type: Object,
      default: () => ({
        shopConfirmedAt: false,
        shopCertificatedAt: false,
      }),
    },
    isSensorDataLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
