<template>
  <CModal
    class="p-shopAboutIotValueModal"
    dialog-id="shopConfirmList"
    :show="show"
    :close-to="closeTo"
    wysiwyg-content
  >
    <template slot="title">{{ $t('判定基準値について') }}</template>
    <template slot="content">
      <p
        v-t="'センサーで取得した各チェック項目の判定基準値は下記となります。'"
      />
      <CImage class="p-shopAboutIotValueModal__table" :src="tableImageUrl" />
    </template>
  </CModal>
</template>

<script>
import CModal from '@/components/common/CModal.vue'
import CImage from '@/components/common/CImage.vue'

export default {
  name: 'PShopAboutIotValueModal',
  components: {
    CModal,
    CImage,
  },
  props: {
    closeTo: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    show() {
      return this.$route.hash === '#aboutIotValue'
    },
    tableImageUrl() {
      let tableImageUrl
      switch (this.$i18n.locale) {
        case 'en':
          tableImageUrl = '/img/shop/img_about_iot_value_table_en.png'
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return tableImageUrl || '/img/shop/img_about_iot_value_table.png'
    },
  },
}
</script>
