<template>
  <ShopDetailPresentation
    :key="presentationKey"
    v-if="!isLoading"
    :is-public-resources="isPublicResources"
    :shop="shop"
    :facility="facility"
    :organization="organization"
    :measures="measures"
    :measure-image-list="measureImageList"
    :shop-confirm-list="shopConfirmList"
    :trust-index="trustIndex"
    :information-list="informationList"
    :main-sensor="mainSensor"
    :has-sensor-list="hasSensorList"
    :sensor-list="sensorList"
    :is-sensor-data-loading="isSensorDataLoading"
    :certificate-list="certificateList"
    :certificate-data="certificateData"
    :information-data="informationData"
    :survey-info="surveyInfo"
    :survey-data.sync="surveyData"
    :loading-stack="loadingStack"
    @submit:survey="handleSubmitSurvey"
  />
</template>

<script>
import { defaultLayoutViewMixin } from '@/mixins'
import { requestShopDetailData } from '@/assets/request'
import ShopDetailPresentation from '@/components/shop/ShopDetailPresentation.vue'
import { requestCanceler } from '@/assets/modules/request'
import { getSensorDataJson } from '@/assets/request/json/getSensorDataJson'
import { getSensorData } from '@/assets/request/api/getSensorData'
import { postSurveyData } from '@/assets/request/api/postSurveyData'
import { postAccessLog } from '@/assets/request/api/postAccessLog'
import { getAccessDecision } from '@/assets/request/api/getAccessDecision'

export default {
  name: 'ShopDetail',
  mixins: [defaultLayoutViewMixin],
  components: {
    ShopDetailPresentation,
  },
  metaInfo() {
    return {
      title: this.shop === null ? '' : this.shopName,
    }
  },
  data() {
    return {
      isLoading: true,
      isPublicResources: true,
      shop: null,
      facility: null,
      organization: null,
      measures: null,
      measureImageList: [],
      shopConfirmList: [],
      trustIndex: 0,
      informationList: [],
      mainSensor: null,
      sensorMetaList: [],
      sensorDataList: [],
      sensorDataRequestList: [],
      isSensorDataLoading: true,
      certificateList: [],
      certificateData: null,
      informationData: null,
      surveyInfo: [],
      surveyData: [],
      loadingStack: [],
    }
  },
  computed: {
    presentationKey() {
      return `${this.$route.params.portalId}-${this.$route.params.id}`
    },
    shopName() {
      let shopName
      switch (this.$i18n.locale) {
        case 'en':
          shopName = this.shop.nameEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return shopName || this.shop.name
    },
    hasSensorList() {
      // return this.sensorMetaList.length !== 0
      return this.sensorMetaList.length > 1
    },
    sensorList() {
      return this.sensorMetaList
        .map((item) => {
          const sensorData = this.sensorDataList.find(
            ({ sensorId }) => sensorId === item.sensorId
          )

          if (!sensorData) {
            return null
          }

          return {
            ...sensorData,
            name: item.sensorName,
            nameEnglish: item.sensorNameEnglish,
            roomName: item.roomName,
            roomNameEnglish: item.roomNameEnglish,
            roomInfo: item.roomInfo,
            roomInfoEnglish: item.roomInfoEnglish,
          }
        })
        .filter((item) => item)
    },
  },
  async beforeRouteEnter(to, _from, next) {
    requestShopDetailData(to.params.id).then((response) => {
      if (response.isError) {
        next({ name: 'Error' })
        return
      }

      next((vm) => {
        vm.setData(response.data)

        if (to.hash === '#shopCertificate') {
          vm.setCertificateData(to.query.id)
        }
        if (to.hash === '#shopNews') {
          vm.setInformationData(to.query.id)
        }
      })
    })
  },
  async beforeRouteUpdate(to, from, next) {
    if (from.name === to.name && from.params.id === to.params.id) {
      if (to.hash === '#shopCertificate') {
        this.setCertificateData(to.query.id)
      }
      if (to.hash === '#shopNews') {
        this.setInformationData(to.query.id)
      }
      next()
      return
    }

    requestCanceler.cancel()
    await requestShopDetailData(to.params.id).then((response) => {
      if (response.isError) {
        next({ name: 'Error' })
        return
      }

      this.setData(response.data)
      next()
    })
  },
  beforeRouteLeave(_to, _from, next) {
    requestCanceler.cancel()
    next()
  },
  watch: {
    async sensorMetaList(next) {
      this.sensorDataList = []
      this.isSensorDataLoading = true

      const result = await getAccessDecision(this.$route.params.id)
      if (result.isError) {
        this.isSensorDataLoading = false
        return
      }
      this.sensorDataList = (
        await Promise.all(
          next.map(({ isPublicResource, sensorId, roomId }) =>
            isPublicResource
              ? // ? getSensorDataJson(this.$route.params.id, sensorId, roomId)
                // : getSensorData(this.$route.params.id, sensorId, roomId)
                getSensorDataJson(result.data.pageUrl, sensorId, roomId)
              : getSensorData(result.data.pageUrl, sensorId, roomId)
          )
        )
      )
        .filter(({ isError }) => !isError)
        .map(({ data }) => {
          return {
            sensorId: data.sensorId,
            roomId: data.roomId,
            dataList: data.sensorData,
          }
        })
      this.isSensorDataLoading = false
    },
  },
  methods: {
    setData(vm) {
      this.shop = vm.shop
      this.facility = vm.facility
      this.organization = vm.organization
      this.measures = vm.measures
      this.shopConfirmList = vm.shopConfirmList
      this.trustIndex = vm.trustIndex
      this.informationList = vm.informationList
      this.mainSensor = vm.mainSensor
      this.sensorMetaList = vm.sensorMetaList
      this.certificateList = vm.certificateList
      this.measureImageList = vm.measureImageList
      this.isPublicResources = vm.isPublicResources
      this.isLoading = false
      this.surveyInfo = vm.surveyInfo
      this.surveyData = vm.surveyInfo
        .filter(({ published }) => published)
        .map(() => '')

      this.$store.commit('detail/setHasEnglishPage', vm.hasEnglishPage)
    },
    setCertificateData(id) {
      this.certificateData = this.certificateList.find((item) => item.id === id)
    },
    setInformationData(id) {
      this.informationData = this.informationList.find((item) => item.id === id)
    },
    handleSubmitSurvey() {
      this.postSurveyData()
    },
    pushLoadingStack(name) {
      if (this.loadingStack.includes(name)) return
      this.loadingStack.push(name)
    },
    omitLoadingStack(name) {
      const index = this.loadingStack.indexOf(name)
      if (index < 0) return
      this.loadingStack.splice(index, 1)
    },
    checkLoading(name) {
      return this.loadingStack.includes(name)
    },
    async postSurveyData() {
      if (this.checkLoading('postSurveyData')) return

      const result_ad = await getAccessDecision(this.$route.params.id)
      // const result_ad = await getAccessDecision('error')
      if (result_ad.isError) {
        this.$toasted.show(
          this.$t('アンケート回答登録時にエラーが発生しました。')
        )
        return
      }

      const data = this.surveyData.concat()
      const [facilityId, sectionId] = result_ad.data.pageUrl
        .replace(/(\d{8})(\d{8})/, '$1,$2')
        .split(',')
      const answerResult = this.surveyInfo.map(({ question, published }) => {
        return published
          ? { question, answer: data.shift() }
          : { question: '', answer: '' }
      })
      this.pushLoadingStack('postSurveyData')
      const result = await postSurveyData(facilityId, sectionId, answerResult)
      this.omitLoadingStack('postSurveyData')

      if (result.isError) {
        this.$toasted.show(
          this.$t('アンケート回答登録時にエラーが発生しました。')
        )
      } else {
        this.$toasted.show(this.$t('アンケート回答結果の登録が完了しました。'))
        this.surveyData = this.surveyInfo
          .filter(({ published }) => published)
          .map(() => '')
      }
    },
  },
  async mounted() {
    const result_ad = await getAccessDecision(this.$route.params.id)
    if (result_ad.isError) {
      return
    } else {
      postAccessLog(result_ad.data.pageUrl, 'detail', location.href)
    }
  },
}
</script>
