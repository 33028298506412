import { isFocusable } from '@/assets/modules/wai-aria/util'

export let IgnoreUtilFocusChanges = false

/**
 *最初の要素にフォーカスする
 * @export
 * @param {HTMLElement} element
 * @returns {boolean}
 */
export function focusFirstDescendant(element) {
  return [].slice
    .call(element.childNodes)
    .some((el) => attemptFocus(el) || focusFirstDescendant(el))
}

/**
 *最後の要素にフォーカスする
 * @export
 * @param {HTMLElement} element
 * @returns {boolean}
 */
export function focusLastDescendant(element) {
  return [].slice
    .call(element.childNodes)
    .some((el) => attemptFocus(el) || focusLastDescendant(el))
}

/**
 *フォーカスを試みる
 * @export
 * @param {HTMLElement} element
 * @returns {boolean}
 */
export function attemptFocus(element) {
  if (!isFocusable(element)) {
    return false
  }

  IgnoreUtilFocusChanges = true
  try {
    element.focus()
    // eslint-disable-next-line no-empty
  } catch (e) {}
  IgnoreUtilFocusChanges = false

  return document.activeElement === element
}
