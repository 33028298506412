var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-shopImageGallery"},[_c('div',{ref:"viewport",staticClass:"p-shopImageGallery__viewport"},[_c('transition-group',{staticClass:"p-shopImageGallery__list",attrs:{"tag":"ul","name":"a-shopImageGalleryItem"}},_vm._l((_vm.filteredImages),function(image,index){return _c('li',{key:image.category + index,staticClass:"p-shopImageGallery__item"},[_c('div',{staticClass:"p-shopImageGallery__visual"},[_c('PShopMeasuresIcon',{staticClass:"p-shopImageGallery__icon",attrs:{"icon":image.category,"portal-id":_vm.portalId}}),_c('CImage',{attrs:{"src":image.src,"size":"3p2","cover":""}})],1)])}),0)],1),(_vm.hasMore)?_c('button',{directives:[{name:"t",rawName:"v-t",value:(_vm.buttonLabel),expression:"buttonLabel"}],class:[
      'p-shopImageGallery__button',
      'c-button',
      'c-button--assist',
      'c-button--iconOpen',
      'c-button--iconLeft',
      {
        'c-button--iconUpsideDown': _vm.expanded,
      },
    ],on:{"click":_vm.handleClickMoreButton}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }