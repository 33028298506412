<template>
  <div
    class="p-shopThumbnailSlider"
    v-if="shop.youtubeVideoId || shop.thumbnail"
  >
    <div class="swiper-container">
      <ul class="swiper-wrapper p-shopThumbnailSlider__list">
        <li
          v-if="shop.youtubeVideoId"
          class="swiper-slide p-shopThumbnailSlider__item"
        >
          <PYouTubePlayer
            :video-id="shop.youtubeVideoId"
            size="3p2"
            v-model="youtubePlayed"
          />
        </li>
        <li
          v-if="shop.thumbnail"
          class="swiper-slide p-shopThumbnailSlider__item"
        >
          <!-- <CImage :src="shop.thumbnail" size="3p2" contain /> -->
          <CImage :src="shop.thumbnail" size="3p2" cover />
        </li>
      </ul>
    </div>
    <div v-if="hasSlider" class="p-shopThumbnailSlider__controls">
      <button
        ref="prevButton"
        class="p-shopThumbnailSlider__button p-shopThumbnailSlider__button--prev"
      />
      <ol
        ref="pagination"
        class="swiper-pagination p-shopThumbnailSlider__pagination"
      />
      <button
        ref="nextButton"
        class="p-shopThumbnailSlider__button p-shopThumbnailSlider__button--next"
      />
    </div>
  </div>
</template>

<script>
import PYouTubePlayer from '@/components/common/PYouTubePlayer.vue'
import CImage from '@/components/common/CImage.vue'
import Swiper from 'swiper'

export default {
  name: 'PShopThumbnailSlider',
  components: {
    PYouTubePlayer,
    CImage,
  },
  mounted() {
    if (!this.hasSlider) return
    this.layout = this.getLayout()
    window.addEventListener('resize', this.handleResizeWindow)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResizeWindow)

    if (!this.swiper) return
    this.swiper.destroy()
  },
  props: {
    shop: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      layout: '',
      youtubePlayed: false,
      swiper: undefined,
    }
  },
  computed: {
    sliderEl() {
      return this.$refs.slider
    },
    prevButtonEl() {
      return this.$refs.prevButton
    },
    nextButtonEl() {
      return this.$refs.nextButton
    },
    paginationEl() {
      return this.$refs.pagination
    },
    hasSlider() {
      return this.shop.youtubeVideoId && this.shop.thumbnail
    },
  },
  watch: {
    layout(next, prev) {
      if (!this.hasSlider) return
      if (next === 'smp' && !this.swiper && next !== prev) this.initSlider()
      if (next === 'pc' && this.swiper && next !== prev) this.destroySlider()
    },
  },
  methods: {
    getLayout() {
      return matchMedia('screen and (max-width: 799px)').matches ? 'smp' : 'pc'
    },
    initSlider() {
      this.swiper = new Swiper('.swiper-container', {
        pagination: {
          el: this.paginationEl,
          type: 'bullets',
          bulletElement: 'li',
          clickable: true,
        },
        navigation: {
          nextEl: this.nextButtonEl,
          prevEl: this.prevButtonEl,
        },
        on: {
          slideChange: () => {
            this.youtubePlayed = false
          },
        },
      })
    },
    destroySlider() {
      if (!this.swiper) return
      this.swiper.destroy()
      this.swiper = undefined
    },
    handleResizeWindow() {
      this.layout = this.getLayout()
    },
  },
}
</script>
