var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CModal',{staticClass:"p-shopCertificateModal",attrs:{"dialog-id":"shopCertificate","show":_vm.show,"close-to":_vm.closeTo,"wysiwyg-content":false}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.shopCertificateData.shopName)+" 統合検証証明書 ")]),_c('template',{slot:"content"},[_c('div',{staticClass:"p-shopCertificateModalBody"},[_c('div',{staticClass:"p-shopCertificateModalBody__heading p-shopCertificateModalHeading"},[_c('img',{staticClass:"p-shopCertificateModalHeading__logo",attrs:{"src":"/img/common/site_logo.svg","alt":_vm.$t('おみせのトラスト')}}),_c('p',{staticClass:"p-shopCertificateModalHeading__title",domProps:{"textContent":_vm._s('統合検証証明書')}}),_c('dl',{staticClass:"p-shopCertificateModalHeading__date"},[_c('dt',{staticClass:"p-shopCertificateModalHeading__dateTitle",domProps:{"textContent":_vm._s('証明書発行日')}}),_c('dd',{staticClass:"p-shopCertificateModalHeading__dateContent",domProps:{"textContent":_vm._s(
              _vm.$options.filters.dayjsFormat(
                _vm.shopCertificateData.certificatedAt,
                'YYYY年M月D日'
              )
            )}})])]),_c('div',{staticClass:"p-shopCertificateModalBody__content p-shopCertificateModalContent"},[_c('p',{staticClass:"p-shopCertificateModalContent__caption",domProps:{"textContent":_vm._s('下記の個別VCP(衛生管理)に適合しました。')}}),_c('dl',{staticClass:"p-shopCertificateModalContent__table p-shopCertificateModalTable"},[_c('div',{staticClass:"p-shopCertificateModalTable__row"},[_c('dt',{staticClass:"p-shopCertificateModalTable__heading",domProps:{"textContent":_vm._s('検証店舗')}}),_c('dd',{staticClass:"p-shopCertificateModalTable__content",domProps:{"textContent":_vm._s(_vm.shopCertificateData.shopName)}})]),_c('div',{staticClass:"p-shopCertificateModalTable__row"},[_c('dt',{staticClass:"p-shopCertificateModalTable__heading",domProps:{"textContent":_vm._s('検証日')}}),_c('dd',{staticClass:"p-shopCertificateModalTable__content",domProps:{"textContent":_vm._s(
                _vm.$options.filters.dayjsFormat(
                  _vm.shopCertificateData.certificatedAt,
                  'YYYY年M月D日'
                )
              )}})]),_c('div',{staticClass:"p-shopCertificateModalTable__row"},[_c('dt',{staticClass:"p-shopCertificateModalTable__heading",domProps:{"textContent":_vm._s('検証時間')}}),_c('dd',{staticClass:"p-shopCertificateModalTable__content",domProps:{"textContent":_vm._s(
                _vm.$options.filters.dayjsFormat(
                  _vm.shopCertificateData.certificatedAt,
                  'H時mm分'
                )
              )}})]),_c('div',{staticClass:"p-shopCertificateModalTable__row"},[_c('dt',{staticClass:"p-shopCertificateModalTable__heading",domProps:{"textContent":_vm._s('検証項目')}}),_c('dd',{staticClass:"p-shopCertificateModalTable__content",domProps:{"textContent":_vm._s(_vm.shopCertificateData.checkLabel)}})]),_c('div',{staticClass:"p-shopCertificateModalTable__row"},[_c('dt',{staticClass:"p-shopCertificateModalTable__heading",domProps:{"textContent":_vm._s('従業員数')}}),_c('dd',{staticClass:"p-shopCertificateModalTable__content"},[_vm._v(" "+_vm._s(_vm.shopCertificateData.count)+"名 ")])])])])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }