<template>
  <div class="c-tab">
    <div class="c-tab__viewport">
      <transition name="a-tab__scrollButton">
        <button
          v-if="isShowScrollButtonPrev"
          class="c-tab__scrollButton c-tab__scrollButton--prev"
          @click="handleClickScrollButtonPrev"
        />
      </transition>
      <ul
        ref="tabList"
        role="tablist"
        class="c-tab__list"
        @scroll="handleScrollTabList"
      >
        <li
          v-for="(key, index) in keys"
          :key="`tab-${key}`"
          role="presentation"
          class="c-tab__item"
        >
          <a
            ref="tabs"
            role="tab"
            class="c-tab__link"
            :href="`#${panelId(key)}`"
            :aria-controls="panelId(key)"
            :aria-selected="selected === key"
            @click.prevent="handleClickTab(key, index)"
          >
            <slot :name="`tab-${key}`" :selected="selected === key" />
          </a>
        </li>
      </ul>
      <transition name="a-tab__scrollButton">
        <button
          v-if="isShowScrollButtonNext"
          class="c-tab__scrollButton c-tab__scrollButton--next"
          @click="handleClickScrollButtonNext"
        />
      </transition>
    </div>
    <div
      v-for="key in keys"
      :key="`tabpanel-${key}`"
      ref="tabpanels"
      role="tabpanel"
      :id="panelId(key)"
      class="c-tab__panel"
      :aria-hidden="selected !== key"
    >
      <slot
        v-if="selected === key"
        :name="`tabpanel-${key}`"
        :selected="selected === key"
      />
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'CTab',
  props: {
    keys: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hasScrollBar: false,
      isShowScrollButtonPrev: false,
      isShowScrollButtonNext: false,
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResizeWindow)
    this.handleResizeWindow()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResizeWindow)
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(next) {
        this.$emit('input', next)
      },
    },
  },
  methods: {
    panelId(key) {
      return `tabpanel-${key}`
    },
    handleClickTab(key, index) {
      this.selectTab(key)
      this.scrollTabList(this.$refs.tabs[index].offsetLeft - 16)
    },
    selectTab(key) {
      this.selected = key
    },
    handleResizeWindow() {
      this.updateHasScrollStatus()
      this.updateScrollButtonStatus()
    },
    handleScrollTabList() {
      this.updateScrollButtonStatus()
    },
    updateHasScrollStatus() {
      this.hasScrollBar =
        this.$refs.tabList.scrollWidth > this.$refs.tabList.clientWidth
    },
    updateScrollButtonStatus() {
      if (!this.hasScrollBar) {
        this.isShowScrollButtonPrev = this.isShowScrollButtonNext = false
        return
      }

      this.isShowScrollButtonPrev = this.$refs.tabList.scrollLeft !== 0
      this.isShowScrollButtonNext =
        this.$refs.tabList.scrollLeft + this.$refs.tabList.clientWidth <
        this.$refs.tabList.scrollWidth
    },
    handleClickScrollButtonPrev() {
      this.scrollTabList('-=120px')
    },
    handleClickScrollButtonNext() {
      this.scrollTabList('+=120px')
    },
    scrollTabList(scrollLeft = 0) {
      if (!this.hasScrollBar) return

      gsap.killTweensOf(this.$refs.tabList)
      gsap.to(this.$refs.tabList, 0.5, {
        scrollLeft: this.getComputedScrollLeft(scrollLeft),
        ease: 'power4.out',
      })
    },
    getComputedScrollLeft(scrollLeft) {
      if (typeof scrollLeft === 'string') {
        if (/^\+=/.test(scrollLeft)) {
          scrollLeft =
            this.$refs.tabList.scrollLeft +
            parseFloat(scrollLeft.replace('+=', ''))
        } else if (/^-=/.test(scrollLeft)) {
          scrollLeft =
            this.$refs.tabList.scrollLeft -
            parseFloat(scrollLeft.replace('-=', ''))
        } else {
          scrollLeft = parseFloat(scrollLeft)
        }
      }

      return Math.min(
        Math.max(0, scrollLeft),
        this.$refs.tabList.scrollWidth - this.$refs.tabList.clientWidth
      )
    },
  },
}
</script>
