import { requestPublicApi } from '@/assets/modules/request'

/**
 * アンケートの回答を登録する
 * @param {string} facilityId
 * @param {string} sectionId
 * @param {Array} answerResult
 * @returns {Promise<import('@/assets/modules/request/ApiResponse').ApiResponse>}
 */
export function postSurveyData(facilityId, sectionId, answerResult) {
  return requestPublicApi.post('/registerEnqueteAnswers', {
    facilityId,
    sectionId,
    answerResult,
  })
}
