<template>
  <CModal
    class="p-shopCertificateModal"
    dialog-id="shopCertificate"
    :show="show"
    :close-to="closeTo"
    :wysiwyg-content="false"
  >
    <template slot="title">
      {{ shopCertificateData.shopName }} 統合検証証明書
    </template>
    <template slot="content">
      <div class="p-shopCertificateModalBody">
        <div
          class="p-shopCertificateModalBody__heading p-shopCertificateModalHeading"
        >
          <img
            src="/img/common/site_logo.svg"
            :alt="$t('おみせのトラスト')"
            class="p-shopCertificateModalHeading__logo"
          />
          <p
            class="p-shopCertificateModalHeading__title"
            v-text="'統合検証証明書'"
          />
          <dl class="p-shopCertificateModalHeading__date">
            <dt
              class="p-shopCertificateModalHeading__dateTitle"
              v-text="'証明書発行日'"
            />
            <dd
              class="p-shopCertificateModalHeading__dateContent"
              v-text="
                $options.filters.dayjsFormat(
                  shopCertificateData.certificatedAt,
                  'YYYY年M月D日'
                )
              "
            />
          </dl>
        </div>
        <div
          class="p-shopCertificateModalBody__content p-shopCertificateModalContent"
        >
          <p
            class="p-shopCertificateModalContent__caption"
            v-text="'下記の個別VCP(衛生管理)に適合しました。'"
          />
          <dl
            class="p-shopCertificateModalContent__table p-shopCertificateModalTable"
          >
            <div class="p-shopCertificateModalTable__row">
              <dt
                class="p-shopCertificateModalTable__heading"
                v-text="'検証店舗'"
              />
              <dd
                class="p-shopCertificateModalTable__content"
                v-text="shopCertificateData.shopName"
              />
            </div>
            <div class="p-shopCertificateModalTable__row">
              <dt
                class="p-shopCertificateModalTable__heading"
                v-text="'検証日'"
              />
              <dd
                class="p-shopCertificateModalTable__content"
                v-text="
                  $options.filters.dayjsFormat(
                    shopCertificateData.certificatedAt,
                    'YYYY年M月D日'
                  )
                "
              />
            </div>
            <div class="p-shopCertificateModalTable__row">
              <dt
                class="p-shopCertificateModalTable__heading"
                v-text="'検証時間'"
              />
              <dd
                class="p-shopCertificateModalTable__content"
                v-text="
                  $options.filters.dayjsFormat(
                    shopCertificateData.certificatedAt,
                    'H時mm分'
                  )
                "
              />
            </div>
            <div class="p-shopCertificateModalTable__row">
              <dt
                class="p-shopCertificateModalTable__heading"
                v-text="'検証項目'"
              />
              <dd
                class="p-shopCertificateModalTable__content"
                v-text="shopCertificateData.checkLabel"
              />
            </div>
            <div class="p-shopCertificateModalTable__row">
              <dt
                class="p-shopCertificateModalTable__heading"
                v-text="'従業員数'"
              />
              <dd class="p-shopCertificateModalTable__content">
                {{ shopCertificateData.count }}名
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </template>
  </CModal>
</template>

<script>
import CModal from '@/components/common/CModal.vue'

export default {
  name: 'PShopCertificateModal',
  components: {
    CModal,
  },
  props: {
    closeTo: {
      type: [String, Object],
      default: '',
    },
    shopCertificateData: {
      type: [Object],
      default: () => ({
        shopName: '-',
        checkLabel: '-',
        count: '-',
        certificatedAt: '1900-01-01T00:00:00',
      }),
    },
  },
  computed: {
    isModalRoute() {
      return this.getIsModalRoute()
    },
    show() {
      return this.isModalRoute && !!this.shopCertificateData
    },
  },
  methods: {
    getIsModalRoute() {
      return this.$route.hash === '#shopCertificate'
    },
  },
}
</script>
