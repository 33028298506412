<template>
  <component
    :is="tag"
    class="c-button"
    :class="{
      'c-button--loading': isLoading,
    }"
    @click="handleClick"
    :disabled="disabled"
    :tabindex="isLoading ? -1 : null"
    :aria-disabled="isLoading"
  >
    <slot />
    <transition name="a-button__loading">
      <span v-if="isLoading" class="c-button__loadingSpinner">
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </span>
    </transition>
  </component>
</template>

<script>
import CLoadingSpinner from '@/components/common/CLoadingSpinner.vue'

export default {
  name: 'CButton',
  components: {
    CLoadingSpinner,
  },
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(event) {
      if (this.disabled || this.isLoading) event.preventDefault()
    },
  },
}
</script>
