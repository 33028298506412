<template>
  <CTextarea
    class="p-shopSurveyTextarea"
    name="shop-survey-3"
    v-model="model"
    :placeholder="
      $t('ここに記入してください。（任意）.アンケート回答:テキストエリア')
    "
    auto-resize
  />
</template>

<script>
import CTextarea from '@/components/common/CTextarea.vue'

export default {
  name: 'PShopSurveyTextarea',
  components: {
    CTextarea,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(next) {
        this.$emit('input', next)
      },
    },
  },
}
</script>
