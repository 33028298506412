<template>
  <ul class="p-shopHelpList">
    <template v-if="showSensorHelp">
      <li class="p-shopHelpList__item">
        <app-link
          class="p-header__helpLink c-link c-link--help c-link--iconLeft"
          v-t="'グラフの見方について'"
          to="#aboutIotGraph"
          relpace
        />
      </li>
      <li class="p-shopHelpList__item">
        <app-link
          class="p-header__helpLink c-link c-link--help c-link--iconLeft"
          v-t="'判定基準値について'"
          to="#aboutIotValue"
          relpace
        />
      </li>
    </template>
    <li class="p-shopHelpList__item" v-if="status.shopConfirmedAt !== null">
      <app-link
        v-if="status.shopConfirmedAt !== false"
        class="c-link"
        to="#shopConfirmList"
        replace
        v-t="'第三者チェック履歴'"
      />
      <template v-else>
        {{ $t('第三者チェック') }}：{{ $t('対応なし') }}
      </template>
    </li>
    <li v-if="isShowShopCertificatedList" class="p-shopHelpList__item">
      <app-link class="c-link" to="#shopCertificateList" replace
        >統合検証証明書</app-link
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PShopHelpList',
  props: {
    showSensorHelp: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Object,
      default: () => ({
        shopConfirmedAt: false,
        shopCertificatedAt: false,
      }),
    },
  },
  computed: {
    isShowShopCertificatedList() {
      return (
        this.$i18n.locale === 'ja' && this.status.shopCertificatedAt !== false
      )
    },
  },
}
</script>
