<template>
  <dl class="p-shopSurveyItem p-shopSurvey__item">
    <div class="p-shopSurveyItem__container">
      <dt class="p-shopSurveyItem__question">
        <span class="p-shopSurveyItem__questionNum">Q{{ questionNumber }}</span>
        <span class="p-shopSurveyItem__questionText" v-text="questionText" />
      </dt>
      <dd class="p-shopSurveyItem__content">
        <PShopSurveyRadioButton
          v-if="type === '1'"
          :values="['はい', 'いいえ']"
          :name="name"
          v-model="model"
        >
          <template #item-はい>
            {{ $t('はい.アンケート回答:2択') }}
          </template>
          <template #item-いいえ>
            {{ $t('いいえ.アンケート回答:2択') }}
          </template>
        </PShopSurveyRadioButton>
        <PShopSurveyRadioButton
          v-else-if="type === '2'"
          :values="['はい', 'いいえ', 'その他']"
          :name="name"
          v-model="model"
        >
          <template #item-はい>
            {{ $t('はい.アンケート回答:2択') }}
          </template>
          <template #item-いいえ>
            {{ $t('いいえ.アンケート回答:2択') }}
          </template>
          <template #item-その他>
            {{ $t('その他.アンケート回答:2択') }}
          </template>
        </PShopSurveyRadioButton>
        <PShopSurveyRadioButton
          v-else-if="type === '3'"
          :values="[
            '満足',
            'やや満足',
            'どちらともいえない',
            'やや不満',
            '不満',
          ]"
          :name="name"
          v-model="model"
        >
          <template #item-満足>
            {{ $t('満足.アンケート回答:満足度') }}
          </template>
          <template #item-やや満足>
            {{ $t('やや満足.アンケート回答:満足度') }}
          </template>
          <template #item-どちらともいえない>
            {{ $t('どちらともいえない.アンケート回答:満足度') }}
          </template>
          <template #item-やや不満>
            {{ $t('やや不満.アンケート回答:満足度') }}
          </template>
          <template #item-不満>
            {{ $t('不満.アンケート回答:満足度') }}
          </template>
        </PShopSurveyRadioButton>
        <PShopSurveyTextarea v-else-if="type === '4'" v-model="model" />
      </dd>
    </div>
  </dl>
</template>

<script>
import PShopSurveyRadioButton from '@/components/shop/PShopSurveyRadioButton.vue'
import PShopSurveyTextarea from '@/components/shop/PShopSurveyTextarea.vue'

export default {
  name: 'PShopSurveyItem',
  components: {
    PShopSurveyRadioButton,
    PShopSurveyTextarea,
  },
  props: {
    type: {
      type: String,
      validator: (value) => ['1', '2', '3', '4'].includes(value),
      default: '1',
    },
    value: {
      type: String,
      required: true,
    },
    questionNumber: {
      type: [String, Number],
      default: '1',
    },
    questionText: {
      type: String,
      default: '',
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(next) {
        this.$emit('input', next)
      },
    },
    name() {
      return `shop-survey-${this.questionNumber}`
    },
  },
}
</script>
