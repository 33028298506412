<template>
  <header class="p-shopHeader">
    <ul class="p-shopHeaderStatus">
      <li class="p-shopHeaderStatus__item">
        <PSocialLink />
      </li>
      <li
        class="p-shopHeaderStatus__item"
        v-html="
          $t('情報更新日時：{text}', {
            text: $options.filters.dayjsFormat(updatedAt),
          })
        "
      />
    </ul>

    <div class="p-shopHeader__name">
      <h1 class="p-shopHeader__nameText" v-text="shopName" />
      <ul v-if="badges.length" class="p-shopHeader__badgeList">
        <li
          v-for="badge in badges"
          :key="badge"
          class="p-shopHeader__badgeListItem"
        >
          <CTooltip>
            <template slot="target">
              <PShopBadge class="p-shopHeader__badge" :badge="badge" />
            </template>
            <template slot="content">
              <span v-t="badgeBalloon[badge]" />
            </template>
          </CTooltip>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import PSocialLink from '@/components/common/PSocialLink.vue'
import PShopBadge, { BadgeKeys } from '@/components/common/PShopBadge.vue'
import CTooltip from '@/components/common/CTooltip.vue'

export default {
  name: 'PShopHeader',
  components: {
    PSocialLink,
    PShopBadge,
    CTooltip,
  },
  props: {
    updatedAt: {
      type: String,
      default: new Date().toISOString(),
    },
    shopName: {
      type: String,
      default: '',
    },
    badges: {
      type: Array,
      default: () => [],
      validator: (val) => val.every((badge) => BadgeKeys.includes(badge)),
    },
  },
  computed: {
    badgeBalloon() {
      return {
        iotSensor: 'IoTセンサーによるリアルタイム状況を表示しています。',
        shopConfirm: '第三者によるチェックを行っています。',
      }
    },
  },
}
</script>
