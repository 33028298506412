<template>
  <div class="p-shopData">
    <PShopThumbnailSlider class="p-shopData__thumbnail" :shop="shop" />

    <div class="p-shopData__info">
      <div class="p-shopDataList">
        <p class="p-shopDataList__item" v-text="facilityName" />
        <dl class="p-shopDataList__item">
          <dt class="p-shopDataList__title" v-t="'住所'" />
          <dd class="p-shopDataList__text" v-text="address" />
        </dl>
      </div>

      <ul class="p-shopDataLinks p-shopData__links">
        <li v-if="shop.siteUrl" class="p-shopDataLinks__item">
          <app-link
            class="p-shopDataLinks__link c-link c-link--shop c-link--iconLeft"
            :href="shop.siteUrl"
            target="_blank"
            v-t="`${portalId}.おみせのサイトへ`"
            @click="postAccessData(shop.siteUrl)"
          />
        </li>
      </ul>

      <div class="p-shopData__trustIndex">
        <PShopTrustIndex :trust-index="trustIndex" :measures="measures" />
      </div>
    </div>

    <div
      class="p-shopData__comment p-shopDataComment"
      :class="{ 'p-shopDataComment--expanded': commentExpanded }"
    >
      {{ commment }}
      <button
        v-t="'…もっと読む'"
        class="c-link p-shopDataComment__button"
        v-show="!commentExpanded"
        @click="handleClickCommentExpandButton"
      />
    </div>
  </div>
</template>

<script>
import PShopThumbnailSlider from '@/components/shop/PShopThumbnailSlider.vue'
import PShopTrustIndex from '@/components/shop/PShopTrustIndex.vue'
import AppLink from '../AppLink.vue'
import { postAccessLog } from '@/assets/request/api/postAccessLog'
import { getAccessDecision } from '@/assets/request/api/getAccessDecision'

export default {
  name: 'PShopData',
  components: {
    PShopThumbnailSlider,
    PShopTrustIndex,
    AppLink,
  },
  props: {
    shop: {
      type: Object,
      default: null,
    },
    facility: {
      type: Object,
      default: null,
    },
    trustIndex: {
      type: Number,
      required: true,
      default: 0,
    },
    measures: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      commentExpanded: false,
    }
  },
  computed: {
    facilityName() {
      let facilityName
      switch (this.$i18n.locale) {
        case 'en':
          facilityName = this.facility.nameEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return facilityName || this.facility.name
    },
    address() {
      let address
      switch (this.$i18n.locale) {
        case 'en':
          address = this.shop.addressEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return address || this.shop.address
    },
    commment() {
      let comment
      switch (this.$i18n.locale) {
        case 'en':
          comment = this.shop.commentEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return comment || this.shop.comment
    },
  },
  methods: {
    handleClickCommentExpandButton() {
      this.commentExpanded = true
    },
    async postAccessData(site_url){
      const result_ad = await getAccessDecision(this.$route.params.id)
      if (result_ad.isError) {
        return
      } else {
        // postAccessLog(this.$route.params.id, 'siteUrl', site_url)
        postAccessLog(result_ad.data.pageUrl, 'siteUrl', site_url)
      }
    },
  },
}
</script>
