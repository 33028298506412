<template>
  <CModal
    class="p-shopAboutIotGraphModal"
    dialog-id="shopConfirmList"
    :show="show"
    :close-to="closeTo"
    wysiwyg-content
  >
    <template slot="title">{{ $t('グラフの見方について') }}</template>
    <template slot="content">
      <dl>
        <dt v-t="'CO2濃度'" />
        <dd>
          <p
            v-t="
              '屋内における二酸化炭素濃度は、400～1500ppm程度の間を上下します。屋内でも窓/換気扇/入口等の開閉により、濃度は低くなります。'
            "
          />
          <p
            v-t="
              '濃度が低ければ、屋外の二酸化炭素濃度と同等であり、センサー周辺には、二酸化炭素を発生させる原因が少ないものと判断します。逆に濃度が高ければ、密閉空間の換気が不十分であるかもしくは二酸化炭素を発生させる原因が多く存在します。つまり、密集している状態の可能性が高いと考えます。換気状況を見える化できていることを、グラフからご理解頂けます。'
            "
          />
        </dd>
      </dl>
      <dl>
        <dt v-t="'室温'" />
        <dd>
          <p
            v-t="
              '屋内における温度は、季節/冷暖房/換気等の状況変化により、0～35℃程度の間を上下します。'
            "
          />
          <p
            v-t="
              '「機械換気未設置の場合は、常時、窓を少し開け、室温は18 ℃を目安にすること」との内容が政府より公表されています。'
            "
          />
        </dd>
      </dl>
      <dl>
        <dt v-t="'湿度'" />
        <dd>
          <p
            v-t="
              '屋内における湿度は、季節/冷暖房/換気等の状況変化により、0～100%の間を上下します。'
            "
          />
          <p
            v-t="
              '飛沫拡散防止の観点から「湿度は40%以上を目安にすること」との内容が政府より公表されています。'
            "
          />
        </dd>
      </dl>
      <dl>
        <dt v-t="'騒音'" />
        <dd v-t="'店内の人の密集/密接状況を騒音の大小で表示します。'" />
      </dl>
      <dl>
        <dt v-t="'混雑指数'" />
        <dd
          v-t="
            '店内の人の密集/密接状況を周辺のBluetooth機器数から求めた指数で表示します。'
          "
        />
      </dl>
      <dl>
        <dt v-t="'暑さ指数（WBGT（湿球黒球温度）：Wet Bulb Globe Temperature）'" />
        <dd>
          <p
            v-t="
              '室内を前提に、簡易的にWBGTを求めたものであり、熱中症になりやすさを示しています。'
            "
          />
        </dd>
      </dl>
    </template>
  </CModal>
</template>

<script>
import CModal from '@/components/common/CModal.vue'

export default {
  name: 'PShopAboutIotGraphModal',
  components: {
    CModal,
  },
  props: {
    closeTo: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    show() {
      return this.$route.hash === '#aboutIotGraph'
    },
  },
}
</script>
