<template>
  <td class="p-shopSensorInfoValue">
    <span class="p-shopSensorInfoValue__label" v-t="data.label" />
    <div class="p-shopSensorInfoValue__item">
      <template v-if="data.dataList">
        <PShopGraphIcon
          class="p-shopSensorInfoValue__valueIcon"
          :icon-name="iconName"
          :fill-color="iconFillColor"
        />
        <p
          :class="['p-shopSensorInfoValue__valueText', computedValueTextClass]"
          v-text="valueLabel"
        />
      </template>
      <p v-else class="p-shopSensorInfoValue__valueText">-</p>
    </div>
  </td>
</template>

<script>
import dayjs from 'dayjs'
import PShopGraphIcon from '@/components/shop/PShopGraphIcon.vue'

const iconNameMap = {
  attention: 'faceFrowning',
  alert: 'faceNeutral',
  clean: 'faceSmiling',
}

const fillColorMap = {
  attention: 'attention',
  alert: 'alert',
  clean: 'clean',
}

export default {
  name: 'PShopSensorInfoValue',
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  components: {
    PShopGraphIcon,
  },
  computed: {
    computedValueTextClass() {
      return [this.computedLevel].reduce((classList, value) => {
        if (typeof value === 'undefined') return classList

        classList.push('p-shopSensorInfoValue__valueText--' + value)

        return classList
      }, [])
    },
    computedLatestStatus() {
      return this.data.latestStatus.original
    },
    renderedAt() {
      return dayjs(new Date()).second(0).millisecond(0).toISOString()
    },
    value() {
      return this.showStatus ? this.computedLatestStatus.value || '-' : '-'
    },
    unit() {
      return (this.data.unit || '').replace('℃', '°C')
    },
    showStatus() {
      const oneDaysAgo = dayjs(this.renderedAt).subtract(1, 'day')
      const timestamp = dayjs(this.computedLatestStatus.timestamp).toISOString()
      const statusTimestamp = dayjs(timestamp)
      return statusTimestamp.isAfter(oneDaysAgo)
    },
    valueLabel() {
      return `${this.value}${this.unit}`
    },
    computedLevel() {
      return this.showStatus ? this.computedLatestStatus.level : undefined
    },
    iconName() {
      return iconNameMap[this.computedLevel] || 'faceNeutral'
    },
    iconFillColor() {
      return fillColorMap[this.computedLevel] || '#999'
    },
  },
}
</script>
