<template>
  <tr class="p-shopSensorInfoRow" @click="handleClickSensorInfoList">
    <th class="p-shopSensorInfoRow__info">
      <p class="p-shopSensorInfoRow__name" v-text="sensorName" />
      <p
        v-if="roomInfo"
        class="p-shopSensorInfoRow__description"
        v-text="roomInfo"
      />
    </th>
    <PShopSensorInfoValue
      v-for="data in sensorData.dataList"
      :key="data.label"
      :data="data"
      class="p-shopSensorInfoRow__value"
    />
    <td class="p-shopSensorInfoRow__arrow">
      <span class="icon icon--arrow-right" />
    </td>
  </tr>
</template>

<script>
import PShopSensorInfoValue from '@/components/shop/PShopSensorInfoValue.vue'

export default {
  name: 'PShopSensorInfoRow',
  components: {
    PShopSensorInfoValue,
  },
  props: {
    sensorData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sensorName() {
      let sensorName
      switch (this.$i18n.locale) {
        case 'en':
          sensorName = this.sensorData.nameEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return sensorName || this.sensorData.name
    },
    roomInfo() {
      let roomInfo
      switch (this.$i18n.locale) {
        case 'en':
          roomInfo = this.sensorData.roomInfoEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return roomInfo || this.sensorData.roomInfo
    },
    to() {
      return {
        name: 'SensorDetail',
        query: {
          id: this.sensorData.sensorId,
        },
      }
    },
  },
  methods: {
    handleClickSensorInfoList() {
      this.$router.push(this.to)
    },
  },
}
</script>
