var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-container"},[_c('div',{staticClass:"p-shopNews"},[_c('CSelect',{staticClass:"p-shopNews__select",attrs:{"options":_vm.arciveOptions},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}}),(_vm.filteredInformationList.length)?_c('div',{staticClass:"p-shopNews__list p-shopNewsList"},[_c('ul',{staticClass:"p-shopNewsList__list"},_vm._l((_vm.filteredInformationList),function(item){return _c('li',{key:item.id,staticClass:"p-shopNewsList__item p-shopNewsListItem"},[_c('router-link',{staticClass:"p-shopNewsListItem__link",attrs:{"to":{
              hash: '#shopNews',
              query: {
                id: item.id,
              },
            },"replace":""}},[_c('article',{staticClass:"p-shopNewsListItem__inner"},[_c('time',{staticClass:"p-shopNewsListItem__date",domProps:{"textContent":_vm._s(
                  _vm.$options.filters.dayjsFormat(
                    item.publishedAt,
                    _vm.$t('YYYY年M月D日.お知らせpublishedAt')
                  )
                )}}),_c('h1',{staticClass:"p-shopNewsListItem__title",domProps:{"textContent":_vm._s(item.title)}})])])],1)}),0)]):_c('div',{staticClass:"p-shopNews__empty"},[_c('p',{directives:[{name:"t",rawName:"v-t",value:('登録情報がありません。'),expression:"'登録情報がありません。'"}]})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }