var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"p-shopSurveyItem p-shopSurvey__item"},[_c('div',{staticClass:"p-shopSurveyItem__container"},[_c('dt',{staticClass:"p-shopSurveyItem__question"},[_c('span',{staticClass:"p-shopSurveyItem__questionNum"},[_vm._v("Q"+_vm._s(_vm.questionNumber))]),_c('span',{staticClass:"p-shopSurveyItem__questionText",domProps:{"textContent":_vm._s(_vm.questionText)}})]),_c('dd',{staticClass:"p-shopSurveyItem__content"},[(_vm.type === '1')?_c('PShopSurveyRadioButton',{attrs:{"values":['はい', 'いいえ'],"name":_vm.name},scopedSlots:_vm._u([{key:"item-はい",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('はい.アンケート回答:2択'))+" ")]},proxy:true},{key:"item-いいえ",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('いいえ.アンケート回答:2択'))+" ")]},proxy:true}],null,false,2166688265),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):(_vm.type === '2')?_c('PShopSurveyRadioButton',{attrs:{"values":['はい', 'いいえ', 'その他'],"name":_vm.name},scopedSlots:_vm._u([{key:"item-はい",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('はい.アンケート回答:2択'))+" ")]},proxy:true},{key:"item-いいえ",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('いいえ.アンケート回答:2択'))+" ")]},proxy:true},{key:"item-その他",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('その他.アンケート回答:2択'))+" ")]},proxy:true}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):(_vm.type === '3')?_c('PShopSurveyRadioButton',{attrs:{"values":[
          '満足',
          'やや満足',
          'どちらともいえない',
          'やや不満',
          '不満',
        ],"name":_vm.name},scopedSlots:_vm._u([{key:"item-満足",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('満足.アンケート回答:満足度'))+" ")]},proxy:true},{key:"item-やや満足",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('やや満足.アンケート回答:満足度'))+" ")]},proxy:true},{key:"item-どちらともいえない",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('どちらともいえない.アンケート回答:満足度'))+" ")]},proxy:true},{key:"item-やや不満",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('やや不満.アンケート回答:満足度'))+" ")]},proxy:true},{key:"item-不満",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('不満.アンケート回答:満足度'))+" ")]},proxy:true}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):(_vm.type === '4')?_c('PShopSurveyTextarea',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }