<template>
  <div class="l-container">
    <form class="p-shopSurvey" @submit.prevent="handleSubmit">
      <div class="p-shopSurvey__container">
        <h2 class="p-shopSurvey__title" v-t="'お客様満足度アンケート'" />
        <p
          class="p-shopSurvey__introduction"
          v-t="
            'いつもご利用いただき誠にありがとうございます。よりお客様にご満足いただけるサービスが提供できるよう、アンケートを行っております。ぜひご意見をお聞かせください。'
          "
        />
      </div>

      <div class="p-shopSurvey__list">
        <PShopSurveyItem
          v-for="(item, index) in questions"
          :key="index"
          class="p-shopSurvey__item"
          :value="value[index]"
          :question-number="index + 1"
          :question-text="item.question"
          :type="item.format"
          @input="handleInputValue(index, $event)"
        />
      </div>
      <div class="p-shopSurvey__container">
        <small
          class="p-shopSurvey__note c-note"
          data-mark="※"
          v-t="
            '本アンケートは、各店舗の顧客満足度把握が目的です。個人情報の取得は行いませんので、ご入力はお控え下さい。各店舗への誹謗中傷はお控え下さい。'
          "
        />
        <CButton
          type="submit"
          class="p-shopSurvey__submit"
          :disabled="submitDisabled"
          :is-loading="isLoading"
        >
          {{ $t('アンケートを送信する') }}
        </CButton>
      </div>
    </form>
  </div>
</template>

<script>
import PShopSurveyItem from '@/components/shop/PShopSurveyItem.vue'
import CButton from '@/components/common/CButton.vue'

const RequiredTypeList = ['1', '2', '3']

export default {
  name: 'PShopSurvey',
  components: {
    PShopSurveyItem,
    CButton,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    submitDisabled() {
      return this.questions.some(
        ({ format }, index) =>
          RequiredTypeList.includes(format) && this.value[index] === ''
      )
    },
  },
  methods: {
    handleInputValue(index, value) {
      const next = this.value.concat()
      next[index] = value

      this.$emit('input', next)
    },
    handleSubmit() {
      this.$emit('submit:survey')
    },
  },
}
</script>
