<template>
  <div ref="wrap" class="c-textarea">
    <textarea
      ref="textarea"
      class="c-textarea__input"
      rows="3"
      :name="name"
      v-model="model"
      :maxlength="maxlength"
      :placeholder="placeholder"
      @input="handleInputTextarea"
    />
  </div>
</template>

<script>
export default {
  name: 'CTextarea',
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    maxlength: {
      type: String,
      default: undefined,
    },
    autoResize: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(next) {
        this.$emit('input', next)
      },
    },
  },
  mounted() {
    this.updateTextareaHeight()
  },
  methods: {
    handleInputTextarea() {
      this.updateTextareaHeight()
    },
    updateTextareaHeight() {
      if (!this.autoResize) return
      this.$refs.wrap.style.height = '0'
      this.$refs.wrap.style.height = `${this.$refs.textarea.scrollHeight + 2}px`
    },
  },
}
</script>
